.users-pestid-wrapper{
    //background-color: #F6F6F6;
    & > .users-pestid-header{
        display: flex;
        align-items: center;
        padding: 20px 20px;
        background-color: #ffff;
        & > .users-pestid-header-title{
            flex: 1;
            & > h1 {
                font-size: 21px;
            }
        }
        & > .users-pestid-header-button > .button-component{
            background-color: #14AF8D;
        }
    }

    & > .users-pestid-content{
        padding: 0px 20px;
        margin-top: 30px;
        & > .users-pestid-table > .users-pestid-table-no-content{
            display: flex;
            align-items: center;
            & > h3 {
                margin-left: 15px;
            }
        }
    }
}