@import "designSystem/colors.scss";

.users-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    background-color: white;
    padding: 20px 40px;
    box-sizing: border-box;

    h1 {
        font-size: 21px;
        margin: 0;
        font-weight: 600;
        color: $dark-background;
    }
}

.modal-pest-wrapper {
    width: 700px;

    .modal-pest-image-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        // align-items: center;

        .bug-image {
            border-radius: 8px;
            width: 160px;
            height: 160px;
            margin-left: 30px;

            &.background {
                background-color: $primary-medium;
            }

            &.bigger {
                width: 200px;
                height: 200px;
            }
        }

        .bug-infos {
            display: flex;
            flex-direction: row;
            color: $font-primary;
            padding: 10px 20px;

            .info-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;

                img {
                    width: 20px;
                    height: 20px;
                }

                &.primary {
                    color: $primary-medium;
                }
            }
            .info {
                margin-left: 15px;
            }

            &.primary {
                color: $primary-medium;
            }

            .close-date {
                margin-top: -5px;
            }
        }
    }

    .input-wrapper {
        padding: 30px;

        .input-style {
            margin: 10px 0;
        }

        .infos-title {
            color: $font-primary;
            margin: 0;
        }

        .checkbox-title {
            font-size: 24px;
        }

        .checkbox-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
}

.resolution-modal-wrapper {
    width: 640px;
    padding: 10px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    .card-wrapper {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;

        .risk-icon {
            width: 45px;
            height: 45px;
        }
    }
}

.last-modal-wrapper {
    width: 700px;
    .input-wrapper {
        padding: 0 30px;

        .date-picker {
            margin-top: 20px;
            padding-bottom: 90px;

            .title {
                margin-left: 5px;
            }

            .map-datepicker {
                padding: 5px 10px;
                border: 1px solid #b1b1b1;
                border-radius: 5px;
                margin: 0 10px;
                font-weight: 500;
                color: $font-title;
            }
        }
    }
}

.closed-modal-wrapper {
    width: 700px;

    .input-wrapper {
        padding: 0 30px;
    }
}

@media only screen and (max-width: 768px) {
    .users-header {
        height: 70px;
        padding: 15px;
    }
}
