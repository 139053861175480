@import "./src/designSystem/colors.scss";

.reset-password-pestid-component {
    display: flex;
    width: 100%;

    .claim {
        background: linear-gradient(0deg, #080c22, #080c22), linear-gradient(222.98deg, #50e3c2 0%, #4ae7ae 101.48%);
        flex: 0 0 50%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .header {
            display: flex;
            justify-content: center;
            padding: 10px 0;

            img {
                width: 100px;
            }
        }

        .footer {
            display: flex;
            justify-content: center;
            padding: 10px 0;
        }

        .claimBody {
            h1 {
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                letter-spacing: 0.02em;
                font-family: GothamRounded;
                font-style: normal;
                font-size: 50px;
                line-height: 60px;
                padding-bottom: 0;
                margin-bottom: 0;
            }

            h2 {
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                letter-spacing: 0.02em;
                font-family: GothamRounded;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
            }
        }
    }

    .powerby {
        font-style: bold;
        font-size: 15px;
        color: white;
    }

    .formContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .success {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            box-sizing: border-box;

            .success-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 3px solid $primary;
                border-radius: 100%;
                font-size: 22px;
                height: 50px;
                width: 50px;
                color: $primary;
            }

            .success-message {
                font-size: 18px;
                margin-top: 20px;
                max-width: 400px;
                text-align: center;
                font-weight: 600;
                color: $font-primary;
            }

            button {
                margin-top: 30px;
                max-width: fit-content;
            }

        }

        form {
            width: 440px;

            .formControl {
                display: block;

                &:first-of-type {
                    margin-bottom: 20px;
                }
            }

            button {
                margin-top: 20px;
            }

            .error {
                color: $error;
                text-align: center;
                margin: 20px;
            }
            .button-new-request{
                background-color: #080c22;
                .button-content{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    p {
                        margin: 0px;
                        padding: 0px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .reset-password-pestid-component {
        flex-direction: column;

        .claim {
            flex: 0 0 250px;
            justify-content: flex-start;

            .header {
                img {
                    width: 75px;
                    object-fit: contain;
                }
            }

            .claimBody {
                h1 {
                    font-size: 25px;
                    margin-top: 0;
                }

                h2 {
                    font-size: 16px;
                }
            }

            .footer {
                .powerby {
                    font-size: 11px;
                }
            }
        }

        .formContainer {
            .success {
                margin-top: 30px;
            }

            form {
                width: 100% !important;
                padding: 20px;
                box-sizing: border-box;
            }
        }
    }
}
