.traps-content-table-cell-image {
    border-radius: 8px;
    display: flex;
    justify-content: center; 
    align-items: center;
    padding: 5px 0px;
    border: solid 1px #D9D9D9;
    cursor: pointer;
    & p {
        margin: 0px 0px 0px 5px;
    }
}

.traps-table-button{
    border: solid 1px #D9D9D9;
    padding: 3px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    cursor: pointer;
    & svg {
        width: 18px;
    }
}

.traps-content-search-table {
    border-radius: 10px;
    border: solid 1px lightgray;
    background-color: white;
    margin-top: 10px;
    padding: 0px 10px;
}