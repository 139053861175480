.toastr-content-wrapper {
    display: flex;

    &.success {
        background-color: rgba(62, 207, 142, 0.7);
        transition: background-color 0.2s;

        &:hover {
            background-color: rgba(62, 207, 142, 0.8);
            transition: background-color 0.2s;
        }

        .toastr-progress-bar {
            background-color: #57be91;
        }
    }

    &.danger {
        background-color: rgba(202, 91, 83, 0.8);
        transition: background-color 0.2s;

        &:hover {
            background-color: rgba(202, 91, 83, 1);
            transition: background-color 0.2s;
        }

        .toastr-progress-bar {
            background-color: #8c4a45;
        }
    }

    &.warning {
        background-color: rgba(249, 168, 32, 0.8);
        transition: background-color 0.2s;

        &:hover {
            background-color: rgba(249, 168, 32, 1);
            transition: background-color 0.2s;
        }

        .toastr-progress-bar {
            background-color: #a8772a;
        }
    }

    &.info {
        background-color: rgba(82, 169, 194, 0.8);
        transition: background-color 0.2s;

        &:hover {
            background-color: rgba(82, 169, 194, 1);
            transition: background-color 0.2s;
        }

        .toastr-progress-bar {
            background-color: #467988;
        }
    }

    .toastr-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        flex-shrink: 0;
        font-size: 20px;

        svg {
            path {
                color: #fff;
            }
        }
    }

    .toastr-content {
        color: #fff;
        padding: 15px 15px 15px 0;

        h4 {
            margin: 0 0 8px 0;
            font-size: 15.5px;
            font-weight: 700;
            color: #fff;
        }

        p {
            margin: 0;
            margin-right: 5px;
            font-weight: 500;
            font-size: 13.5px;
            line-height: 16px;
        }
    }
}
.toastr-div {
    .toastr-wrapper {
        display: flex;
        width: 320px;
        z-index: 100000;
        top: 50px;
        right: 20px;
        border-radius: 5px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        position: relative;
        padding: 0;

        &:hover {
            cursor: pointer;

            .toastr-close {
                opacity: 0.8;
                transition: opacity 0.2s;
            }
        }

        .toastr-close {
            position: absolute;
            top: 10px;
            right: 10px;
            color: #fff;
            opacity: 0.3;
            transition: opacity 0.2s;
        }

        .toastr-progress-bar {
            height: 5px;

            &.success {
                background: #57be91;
            }

            &.danger {
                background: #8c4a45;
            }

            &.warning {
                background: #a8772a;
            }

            &.info {
                background: #467988;
            }
        }
    }
}
