
.modal-body-alert-delete {
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
}
.modal-footer-alert-delete {
    flex-direction: row;
    display: flex;
    justify-content: center;
    & > button {
        width: 120px;
        margin-right: 20px;
    }
}