.MuiFormLabel-root.Mui-focused {
    color: #50e3c2 !important;
}

.MuiInput-underline:after {
    border-bottom-color: #50e3c2;
}

:hover .MuiInput-underline:after {
    border-bottom-color: #50e3c2;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-color: #50e3c2;
}