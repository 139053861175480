.add-trap-modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: white;
    width: 50%;
    height: min-content;
    box-shadow: 0px 1px 51px rgba(0, 0, 0, 0.04);
    border-radius: 21px;
    .add-trap-modal-wrapper {
        display: flex;
        flex-direction: column;
        padding: 2rem;
        .modal-content-buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            gap: 0.5rem;
            button {
                width: 10%;
            }
        }
    }
}


.react-modal > .modal-body > .modal-body-content {
    & > .add-traps-search-box-input{
    background-color: #F2F2F2;
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 3px 10px;
        & > div {
            margin-left: 10px;
        }
    }
    & > .modal-body-content-add-traps-no-available{
        display: flex;
        flex-direction: row;
        align-items: center;
        & > p {
            margin-left: 8px;
            font-weight: 600;
        }
    }
    & > .modal-body-content-row-add-trap {
        display: flex;
        & > .modal-body-content-row-wrapper-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            & > svg{
                width: 30px;
            }
        }
        & > .modal-body-content-row-description{
            width: 100%;
            padding: 20px;
            & > h4 {
                margin: 0px 0px 10px 0px;
                padding: 0px;
            }
            & > p {
                margin: 0px;
                padding: 0px;
            }
        }
    }
}
