@import "./src/designSystem/colors.scss";

.login-pestid-wrapper {
    display: flex;
    width: 100%;

    .login-pestid-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        
        & > .wrapper-tabs{
            display: flex;
            justify-content: space-between;
            background-color: #50E3C21C;
            border-radius: 50px;
            padding: 10px;
            width: 440px;
            margin-bottom: 30px;
            & > .button-component {
                align-self: center;
                width: 48%;
                padding: 0px;
                background-color: #178068;
                color: white;
                .button-access-pestid{
                    display: flex;
                    align-items: center;
                }
                
            }
        }
        .login-pestid-form-content{
            min-height: 300px;
            form {
                width: 440px;
    
                .formControl {
                    display: block;
    
                        margin-bottom: 20px;
                }
    
                .forgotPassword {
                    display: block;
                    text-decoration: underline;
                    color: $primary;
                    width: 100%;
                    margin-bottom: 20px;
                    margin-top: 20px;
                    text-align: right;
                    font-size: 14px;
                    font-weight: 500;
                    cursor: pointer;
                }
    
                .separator {
                    display: block;
                    margin: 10px 0;
                    text-align: center;
                    font-size: 14px;
                    line-height: 17px;
                    color: $font-primary;
                    font-weight: 600;
                }
    
                .error {
                    color: $error;
                    text-align: center;
                    margin: 20px;
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .login-pestid-wrapper {
        flex-direction: column;

        .claim {
            flex: 0 0 250px;
            justify-content: flex-start;

            .header {
                img {
                    width: 75px;
                    object-fit: contain;
                }
            }

            .claimBody {
                h1 {
                    font-size: 25px;
                    margin-top: 0;
                }

                h2 {
                    font-size: 16px;
                }
            }

            .footer {
                .powerby {
                    font-size: 11px;
                }
            }
        }

        .login-pestid-form {
            flex: 1;
            .wrapper-tabs{
                width: 60%;
            }
            .login-pestid-form-content{
                width: 60%;
                form {
                    width: 100% !important;
                    padding: 20px;
                    box-sizing: border-box;
                }
            }
        }
    }
}


@media (max-width: 767px) {
    .login-pestid-wrapper {
        flex-direction: column;
        .claim {
            flex: 0 0 250px;
            justify-content: flex-start;

            .header {
                img {
                    width: 75px;
                    object-fit: contain;
                }
            }

            .claimBody {
                h1 {
                    font-size: 23px;
                    margin-top: 0;
                    line-height: 30px;
                }

                h2 {
                    font-size: 16px;
                    line-height: 28px;
                    font-weight: 400;
                }
            }

            .footer {
                .powerby {
                    font-size: 11px;
                }
            }
        }

        .login-pestid-form {
            flex: 1;
            padding-top: 30px;
            .wrapper-tabs{
                width: 90%;
            }
            .login-pestid-form-content{
                width: 90%;
                form {
                    width: 100% !important;
                    padding: 20px;
                    box-sizing: border-box;
                }
            }
        }
    }
}