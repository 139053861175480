@import 'designSystem/colors.scss';
.traps-wrapper-loading{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.traps-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #F6F6F6;

    & > .traps-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        padding: 20px 40px;
        box-sizing: border-box;

        h1 {
            font-size: 21px;
            margin: 0;
            font-weight: 600;
            color: $dark-background;
        }
        & > .traps-header-button {
            max-width: 158px;
            height: 45px;
            background: #14AF8D;
            border-radius: 47px;
            color: white;
        }
    }
    & >.traps-content-wrapper {
        background-color: transparent !important;
        & > .traps-content-table{
            margin: 20px 0px;
            width: auto;
            & > table {
                width: 100%;
            }
        }
        & > .traps-content-table::-webkit-scrollbar{
            display: none;
        }
    }
}