.companies-wrapper{
    background-color: #F6F6F6;
    & > .companies-header{
        display: flex;
        align-items: center;
        padding: 20px 20px;
        background-color: #ffff;
        & > .companies-header-title{
            flex: 1;
            & > h1 {
                font-size: 21px;
            }
        }
        & > .companies-header-button > .button-component{
            background-color: #14AF8D;
        }
    }

    .companies-content{
        padding: 0px 20px;
        margin-top: 30px;
    }
}