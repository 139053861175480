@import "designSystem/colors.scss";

.button-component {
    padding: 10px 28px 9px 28px;
    color: white;
    border: none;
    text-decoration: none;
    border-radius: 53px;
    cursor: pointer;
    width: 100%;
    font-size: 14px;
    font-family: "GothamRounded";
    font-weight: 600;
    background-color: $primary;

    &.unset {
        width: unset;
        margin: 5px 0;
    }

    .button-content {
        display: flex;
        align-items: center;
        justify-content: center;

        & > svg {
            margin-right: 5px;
        }
    }

    &.secondary {
        color: $font-light;
        background-color: $dark-background;
    }

    &.link {
		color: $primary;
		background: none;
		border-color: transparent;

		&:hover {
			color: $primary-dark;
		}
	}

    &.white {
        color: $dark-background;
        background-color: $font-light;
        border: 1px solid $dark-background;
    }

    &.auto-width {
        width: auto;
    }

    &.accent {
        background-color: $dark-background;
    }

    &.cancel {
        background-color: #b5b5b5;
    }

    &:focus {
        outline: 0;
    }

    &:hover {
        opacity: 0.9;
    }

    &.disabled {
        opacity: 0.5;
    }

    &.round {
        border-radius: 32px;
    }

    &.light {
        background-color: unset;
        color: $font-primary;
    }

    &.small {
        padding: 7px 15px;
        width: auto;
    }

    &.no-actions {
        pointer-events: none;
    }

    &.danger {
        background-color: $error;
    }
}
