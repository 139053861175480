@import "designSystem/colors.scss";

.skeleton-review-card {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 10px 20px;
    border-radius: 12px;
    box-shadow: 0px 6px 26px rgba(0, 0, 0, 0.02);
    margin-bottom: 30px;
    position: relative;
    box-sizing: border-box;
    height: 60px;

    .review-picture {
        margin-right: 15px;
    }

    .review-name {
        margin-right: 40px;
    }

    .review-message {
        flex: 1;
    }

    .review-stars {
        display: flex;
        align-items: center;
        color: #f2f2f2;
        margin-right: 60px;
        margin-left: 30px;

        svg {
            margin: 0 2px;

            &.green {
                color: #50e3c2;
            }

            &.yellow {
                color: #ffc940;
            }
        }
    }

    .review-actions {
        display: flex;
        align-items: center;
        width: 150px;
        justify-content: flex-end;

        span {
            margin-left: 5px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .skeleton-review-card {
        flex-direction: column;
        height: unset;

        .review-picture {
            margin: 0 0 15px 0;
        }

        .review-name {
            margin: 0 0 15px 0;
        }

        .review-message {
            margin: 0 0 15px 0;
        }

        .review-stars {
            margin: 0 0 15px 0;
        }

        .review-actions {
            width: unset;

            span:first-of-type {
                margin: 0;
            }
        }
    }
}
