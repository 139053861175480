.search-bar__input {
    display: flex;
    align-items: center;
    width: 100%;
    color: white;

    input {
        margin-left: 20px;
        flex: 1;
        background-color: transparent;
        border: 0;
        height: 100%;
        color: white;
        font-size: 15px;

        &:hover {
            cursor: text;
        }

        &:focus {
            outline: 0;
        }

        &::placeholder {
            color: white;
            font-weight: 400;
        }
    }
}

@media only screen and (max-width: 768px) {
    .search-bar__input {
        display: none;
    }
}
