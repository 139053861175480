.react-modal {
    & > .modal-header >.modal-trap-header-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    & > .modal-body > .modal-trap-body-content {
        padding: 0px 35px;   
        height: 100%; 
        & > .modal-trap-body-content-success {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            flex-direction: column;
            height: 100%;
        }
        & > .modal-trap-body-content-selector-type{
            display: flex;
            align-items: center;
            margin-top: 20px;
            & > svg {
                margin-left: 15px;
            }
        }
        & > .modal-trap-body-content-selector-type-container{
            position: absolute;
            background-color: white;
            z-index: 9999;
            border-radius: 10px;
            padding: 20px;
            box-shadow:  0px 4px 4px rgba(0, 0, 0, 0.25);
        }
        & > .modal-trap-body-content-selector-companies-container{
            position: absolute;
            background-color: white;
            z-index: 9999;
            border-radius: 10px;
            box-shadow:  0px 4px 4px rgba(0, 0, 0, 0.25);
            width: 70%;
            height: -webkit-fill-available;
            overflow: overlay;
            & input {
                border: solid 1px gray;
                margin: 5px 10px;
                padding: 5px 5px;
                border-radius: 5px;
            }
            & .modal-users-pestid-body-content-selector-companies-row{
                padding: 5px 20px;
                & p{
                    font-size: 15px;
                    font-weight: 500;
                }
            }
           
        }
    }

    & > .modal-footer > .modal-trap-footer-content {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
                & > .modal-trap-footer-button {
                    width: 95px;
                    height: 45px;
                }
    }
}

.modal-trap-body-content-upload-button {
    height: 152px;
    background-color: rgba(20, 175, 141, 0.05);
    border-radius: 10px;
    border: dashed 2px #14AF8D;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    & > .modal-trap-body-content-delete-button{
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 100px;
        padding: 5px;
        box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.1);
        }

    & > input {
        display: none;
    }
    & > p {
        width: 40%;
        text-align: center;
        margin: 0px;
    }
    & > h3 {
        margin: 0px;
    }
    & > img {
        height: 100%;
        object-fit: contain;
    }
}

.modal-trap-body-content-upload-route{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(20, 175, 141, 0.07);
    margin: 20px 0px;
    border: dashed 2px #14AF8D;
    border-radius: 10px;
    & > h3{
        color: #14AF8D;
    }
}

.back {
    background-color: white;
    color: gray;
}

.next {
    background-color: black;
}