@import 'designSystem/colors.scss';

.react-modal {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    height: 100%;

    background-color: #fff;

    .modal-header {
        padding: 20px;
        font-weight: 600;
        color: $font-primary;
    }

    .modal-body {
        flex: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
    }

    .modal-footer {
        padding: 15px 30px;
        width: 100%;
        box-sizing: border-box;

        .actions {
            width: 100%;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            button {
                margin-left: 20px;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .react-modal {
        .modal-body {
            margin-bottom: 30px;
        }
    }
}
