@import "src/designSystem/colors.scss";

.chat-contact-component {
    display: flex;
    align-items: center;
    // justify-content: center;
    padding: 20px;
    border-bottom: 1px solid $medium-background;
    text-decoration: none;
    color: $font-primary;

    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }

    &.active {
        background-color: $primary;
        color: white;
    }

    img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: gray;
        margin-right: 7px;
    }
    .contact-name {
        font-size: 14px;
    }
    .badge {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $primary;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        border-radius: 50%;
        // position: absolute;
        // right: 10px;
        color: white;
        font-weight: 600;
        font-size: 11px;
    }
}
