@import "./src/designSystem/colors.scss";

.reports-header-pest-type {
    display: flex;
    align-items: center;
    margin-left: 10px;

    h2 {
        font-size: 18px;
        margin: 0;
        font-weight: 600;
        color: $dark-background;
    }

    * + div {
        margin-left: 5px;
        width: 150px;
    }
}

.facility-report-wrapper {
    padding: 30px 40px;

    .graph-wrapper {
        width: 100%;
        box-sizing: border-box;
        background-color: $light-background;
        padding: 24px;
        border-radius: 17px;
        // padding-bottom: 0;

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h2 {
                font-size: 20px;
                margin: 0;
                padding: 0;
                padding-right: 10px;
            }

            .date-filter {
                height: 70px;

                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 20px 0;
                box-sizing: border-box;

                .date-filter-label {
                    margin-right: 10px;
                    font-weight: 600;
                    color: #4a4a4a;
                }

                .map-datepicker {
                    padding: 5px 10px;
                    border: 1px solid #b1b1b1;
                    border-radius: 5px;
                    margin-right: 10px;
                    font-weight: 500;
                    color: $font-title;
                }
            }
        }
    }

    .report-cards-wrapper {
        padding-top: 30px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
    }
}

@media only screen and (max-width: 1080px) {
    .facility-report-wrapper {
        padding: 10px;
        .graph-wrapper {
            .header {
                flex-direction: column;
                align-items: flex-start;
                .date-filter {
                    height: unset;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 100%;
                    .dates-wrapper {
                        margin-top: 5px;
                        width: 100%;
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        grid-gap: 10px;

                        .map-datepicker {
                            box-sizing: border-box;
                            width: 100%;
                            padding: 5px;
                        }
                    }
                }
            }
        }

        .report-cards-wrapper {
            display: flex;
            flex-direction: column;
        }
    }
}
