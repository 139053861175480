@import "designSystem/colors.scss";

.modal-overlay {
    position: fixed;
    z-index: 1000;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    &.with-background {
        background-color: rgba(0, 0, 0, 0.2);
    }
}

.extrasense-modal {
    position: absolute;
    background: rgb(255, 255, 255);
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -40%;
    transform: translate(-50%, -50%);
    padding: 0px;
    overflow: auto;
    border-radius: 11px;
    outline: none;

    .reply-modal__wrapper {
        height: 100%;
        width: 430px;
        display: flex;
        flex-direction: column;

        .modal-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 25px;
            font-weight: 600;
            font-size: 18px;
            color: #454449;

            .close-button {
                &:hover {
                    cursor: pointer;
                    opacity: 0.6;
                }
            }
        }

        .modal-body {
            padding: 0 25px;

            textarea {
                background-color: #f9f9f9;
                border: 0;
                width: 100%;
                border-radius: 5px;
                padding: 20px;
                box-sizing: border-box;
                line-height: 25px;
                font-weight: 500;
                height: 150px;
                resize: none;

                &:focus {
                    outline: 0;
                }
            }
        }

        .modal-footer {
            padding: 20px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            button {
                width: 100px;
            }
        }
    }

    .delete-modal__wrapper {
        height: 100%;
        width: 430px;
        display: flex;
        flex-direction: column;

        .modal-body {
            padding: 25px 25px 0 25px;
            display: flex;
            flex-direction: column;
            align-items: center;

            svg {
                color: $error;
                font-size: 20px;
            }

            h3 {
                margin: 20px 0;
                color: #454449;
                font-weight: 700;
                font-size: 18px;
            }

            p {
                margin: 0;
                color: #acacac;
                font-weight: 500;
                text-align: center;
                font-size: 16px;
                max-width: 260px;
            }
        }

        .modal-footer {
            padding: 20px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            button {
                min-width: unset;
                width: unset;
            }
        }
    }

    .edit-profile-modal__wrapper {
        height: 100%;
        max-height: 90vh;
        width: 750px;
        display: flex;
        flex-direction: column;

        .modal-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 25px;
            font-weight: 600;
            font-size: 18px;
            color: #454449;
        }

        .modal-body {
            padding: 25px 25px 0 25px;
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-gap: 20px;
            width: 100%;
            box-sizing: border-box;

            .fill-row {
                grid-column: span 6;
            }

            .fill-2 {
                grid-column: span 2;
            }

            .fill-3 {
                grid-column: span 3;
            }

            .input-wrapper {
                margin-bottom: 20px;
            }
        }

        .modal-footer {
            padding: 20px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            button {
                min-width: unset;
                width: unset;
            }
        }
    }

    .user-info-modal__wrapper {
        height: 100%;
        width: 500px;
        display: flex;
        flex-direction: column;

        .modal-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 25px;
            font-weight: 600;
            font-size: 18px;
            color: #454449;
        }

        .modal-body {
            padding: 25px;

            .user-picture {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 85px;
                    height: 85px;
                    border-radius: 100%;
                }
            }

            .user-fields {
                display: flex;
                flex-wrap: wrap;

                .user-field {
                    margin-top: 25px;
                    flex: 0 0 50%;

                    .user-field__title {
                        color: rgba(0, 0, 0, 0.54);
                        font-size: 13px;
                        margin-bottom: 5px;
                        font-weight: 600;
                    }

                    .user-field__value {
                        font-size: 15px;
                        word-break: break-all;
                    }
                }
            }
        }

        .modal-footer {
            padding: 20px;
            padding-top: 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            button {
                min-width: unset;
                width: unset;
            }
        }
    }
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.align-center {
    display: flex;
    align-items: center;
}

.Toastify__toast-body{
    padding: 0px;
}

@media only screen and (max-width: 768px) {
    .extrasense-modal {
        width: calc(100% - 40px);

        .edit-profile-modal__wrapper,
        .delete-modal__wrapper,
        .reply-modal__wrapper,
        .user-info-modal__wrapper {
            width: 100%;
        }
    }
}
