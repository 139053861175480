@import "./src/designSystem/colors.scss";

.facility-users-content-wrapper {
    flex: 1;
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-gap: 40px;
    padding: 50px 40px;

    .qr-code-wrapper {
        width: unset;
        height: 175px;
        padding: 20px 30px;
        padding-top: 25px;
        background-color: $light-background;
        border-radius: 14px;
        text-align: center;
        overflow: hidden;

        img {
            width: 200px;
            margin: -30px;
        }

        .qr-description {
            margin-top: 10px;
            font-size: 16px;
            font-weight: bold;
        }
    }

    .table-wrapper {
        width: 100%;

        .table-content,
        .skeleton-table {
            padding: 0;
        }
    }
}

@media only screen and (max-width: 768px) {
    .facility-users-content-wrapper {
        display: flex;
        flex-direction: column;
        padding: 20px 10px;
        .qr-code-wrapper {
            width: unset;
            margin: 0 auto;
        }

        .table-wrapper {
            .table-content {
                .table-content-body {
                    overflow: auto;
                }
            }
        }
    }
}
