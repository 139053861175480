@import 'designSystem/colors.scss';

.building-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    & > .building-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        padding: 20px 40px;
        box-sizing: border-box;

        h1 {
            font-size: 21px;
            margin: 0;
            font-weight: 600;
            color: $dark-background;
        }
        & > .building-header-button {
            width: 140px;
            height: 45px;
            background: black;
            border-radius: 47px;
            color: white;
        }
    }
    & >.building-content-wrapper {
        flex: 1;
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        
        & > .building-buildings-sidebar {
            display: flex;
            flex-direction: column;
            flex-shrink: 0;
            max-height: 50vh;
            overflow-y: auto;
            overflow-x: hidden;
            background: white;

            & > .building-buildings-sidebar-item {
                padding: 1rem 2rem;
                position: relative;
                & > .building-buildings-sidebar-location{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    & > h3 {
                        width: 90%;
                        font-size: 1.05em;
                        margin: 0px 0px 0px 15px;
                    }
                }
                & > .building-buildings-sidebar-item-arrow {
                    position: absolute;
                    right: 5%;
                    top: 50%
                }
                & > div {
                    margin-top: 10px;
                    & > h3 {
                        font-size: 1.12em;
                        margin: 0px;
                    }
                }
            }
        }

        & > .building-content {
            padding: 20px;
            display: flex;
            flex-direction: column;
            & > .building-content-header {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                width: 100%;
                align-items: center;
                margin-bottom: 20px;
                & > .building-content-header-buttons {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    & > .building-content-header-buttons-edit{
                        max-width: 110px;
                        height: 45px;
                        background-color: #14AF8D;
                        margin-right: 20px;
                    }
                    & > .building-content-header-buttons-delete{
                        width: 45px;
                        height: 45px;
                        padding: 10px;
                        background: #FFFFFF;
                        border: 1px solid #F6F6F6;
                        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.09);
                        border-radius: 47px;
                        margin-right: 10px;
                    }
                    & > .building-content-header-buttons-add {
                        width: 140px;
                        height: 45px;
                        padding: 10px;
                        background: #14AF8D;
                    }
                }
                & > .building-content-header-info{
                    width: 100%;
                    & > h2 {
                        font-size: 1.12em;
                        margin: 0px;
                    }
                    & > .building-content-header-location{
                        display: flex;
                        align-items: center;
                        margin: 30px 0px;
                        & > h4 {
                            margin: 0px 0px 0px 20px;
                            font-weight: normal;
                        }
                    }

                }
            }
            & > .building-content-main-route {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                width: 100%;
                background: #FFFFFF;
                border: 1px solid #D8D8D8;
                border-radius: 10px;
                position: relative;
                aspect-ratio: 1/1;
                height: 50%;
                & > .building-content-main-route-wrapper{
                    position: relative;

                    & > .building-content-main-route-heatmap-container{
                        position: absolute !important;
                        top:0;
                        left: 0;
                    }
                }
                & > img {
                    height: 100%;
                    object-fit: contain;
                }
            }
            & > .building-content-building {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 0px 10px 0px;
                overflow-y: hidden;
                overflow-x: auto;
                padding-right: 10px;
                height: 50%;
                & > .building-content-route-item {
                    display: flex;
                    flex-direction: column;
                    box-sizing: border-box;
                    min-width: 50%;
                    margin-right: 10px;
                    background: #F6F6F6;
                    border: 1px solid #D8D8D8;
                    border-radius: 10px;
                    padding: 1rem;
                    & > .building-content-main-route-image {
                        height: 50%;
                        object-fit: contain;
                    }
                    & > h3 {
                        font-size: 16px;
                        line-height: 193.9%;
                        color: #080C22;
                        margin-bottom: 0;
                    }
                    & > p {
                        font-style: normal;
                        font-weight: 325;
                        font-size: 14px;
                        line-height: 193.9%;
                        color: #080C22;
                        margin: 0;
                    }
                    & > .wrapper-canvas {
                        height: 65%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                       
                    }
                }
            }
        }

        & > .building-content-no-buildings {
            display: flex;
            justify-content: center;
            margin: 0px 40px;
            align-items: center;
            height: 50px;
            & > h4 {
                margin-left: 10px;
            }
        }
    } 
}

@media (min-width: 768px) {
    .building-wrapper .building-content-wrapper > .building-content > .building-content-main-route {
        width: 100%;
        & > img {
            width: 60%;
            object-fit: contain !important;
        }
    }
  }
  
@media (min-width: 1024px) {
    .building-wrapper .building-content-wrapper > .building-content {
        & > .building-content-building > .building-content-route-item {
            min-width: 30%;
            & > img {
                width: 100%;
                height: auto;
                align-self: center;
            }
        }
        & > .building-content-header {
            flex-direction: row;
            & > .building-content-header-info > .building-content-header-location{
                margin: 10px 0px 0px 0px;
            }
            & > .building-content-header-buttons{
                justify-content: flex-end;
            }
        } 
    }
}
  
@media (min-width: 1100px) {
    .building-wrapper .building-content-wrapper {
        flex-direction: row;
        height: 100%;
        width: 100%;
        & > .building-buildings-sidebar{
            width: 30%;
            max-height: 100%;
        }
        & > .building-content {
                height: 100%;
                width: calc(70% - 30px);
            & > .building-content-header{
                height: 10%;
            }
            & > .building-content-main-route{
                height: 45%;
                & > img{
                    height: 90%;
                    object-fit: contain;
                }
            }
            & > .building-content-building::-webkit-scrollbar {
                display: none;
            }
            & > .building-content-building {
                height: 45%;
                & > .building-content-route-item {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                  
                }
            }
        }
    }
}