@import "designSystem/colors.scss";

.location-incidence-card {
    box-sizing: border-box;

    &:hover,
    &.selected {
        cursor: pointer;
        background: #f7f7f7;
    }

    .location-incidence-card__wrapper {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #f7f7f7;
        margin: 0 20px;
        padding: 20px 0;

        .location-incidence-title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-weight: 600;
            margin-bottom: 10px;
            font-size: 17px;
            color: $font-title;

            img {
                width: 17px;
                height: 17px;
                object-fit: contain;
            }

            span {
                margin-left: 10px;
            }

            .card-type-wrapper {
                border-radius: 3px;
                font-size: 11px;
                padding: 5px;

                &.pest {
                    background-color: $primary;
                }

                &.facility {
                    background-color: $dark-background;
                    color: $font-light;
                }
            }
        }

        .location-incidence-content {
            font-size: 14px;
            color: $font-primary;
            font-weight: 500;
            line-height: 22px;

            .incidence-item {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 5px;
                width: fit-content;

                &.clickable {
                    color: #52c3aa;
                    font-weight: 500;

                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }

                .incidence-item__icon {
                    width: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }
            }
        }
    }
}
