@import "designSystem/colors.scss";

.species-wrapper {
    .species-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 80px;
        background-color: white;
        padding: 20px 40px;
        box-sizing: border-box;

        h1 {
            font-size: 21px;
            margin: 0;
            font-weight: 600;
            color: $dark-background;
        }
    }

    .top-buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 20px 40px 0px 20px;
        box-sizing: border-box;

    }

    .species-empty {
        padding: 40px;
        box-sizing: border-box;
        font-weight: 500;
        color: $font-primary;

        &.padding-top {
            margin-top: 40px;
        }
    }

    .table-content {
        width: 100%;
        display: flex;
        flex-direction: column;

        tbody {
            width: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            tr {
                flex: 1;
                display: flex;

                td {
                    flex: 1;
                }
            }
        }
        .table-filters-custom {

            .sort-option{
                padding: 0px 30px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .species-wrapper {
        .species-header {
            height: 70px;
            padding: 20px;
        }

        .species-empty {
            padding: 20px;
        }

        .table-content {
            .table-filters .table-sort {
                justify-content: space-between;

                .sort-option {
                    &.name {
                        width: unset;
                        margin: 0;
                    }

                    &.message {
                        flex: 0;
                        margin: unset;
                    }

                    &.rating {
                        margin: 0;
                    }
                }
            }
        }
    }
}
