@import "designSystem/colors.scss";

.skeleton-table {
    padding: 40px;
    box-sizing: border-box;

    table {
        border-collapse: collapse;
        width: 100%;

        &.rounded {
            border-radius: 30px;
            overflow: hidden;
        }
        
        .table-header th,
        .table-row td {
            padding: 25px 30px;
            background-color: white;
            border-bottom: 1px solid #f7f7f7;
            text-transform: capitalize;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            text-align: left;
        }

        .table-row {
            box-shadow: 0px 6px 26px rgba(0, 0, 0, 0.02);
        }
    }
}

@media only screen and (max-width: 768px) {
    .skeleton-table {
        padding: 20px;
    }
}
