@import "designSystem/colors.scss";

.skeleton-contact-card {
    display: flex;
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 6px 26px rgba(0, 0, 0, 0.02);
    margin-bottom: 30px;
    position: relative;
    width: 100%;
    box-sizing: border-box;

    .contact-picture {
        margin-right: 20px;
        height: 50px;
        width: 50px;

        img {
            height: 100%;
            width: 100%;
            border-radius: 10px;
        }
    }

    .contact-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;

        .contact-id {
            font-size: 12px;
            text-transform: uppercase;
            color: $font-primary;
            font-weight: 600;
            margin-bottom: 15px;
        }

        .contact-field {
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex: 1;

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                color: $primary;
                margin-right: 10px;
                width: 15px;
            }

            span {
                color: $font-primary;
                font-weight: 600;
                font-size: 14px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .skeleton-contact-card {
        .contact-content {
            flex-wrap: wrap;
            .contact-field {
                span {
                    font-size: 13px;
                }
            }
        }
    }
}
