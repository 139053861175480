@import 'designSystem/colors.scss';

.reports {
  display: flex;
  height: 100%;
  .reports-container {
    * {
      font-family: 'GothamRounded';
    }

    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
    .section-titles {
      display: flex;
      width: 20%;
      height: 100%;
      flex-direction: column;
      justify-content: flex-end;
      align-items: space-between;
      h4 {
        flex: 1;
        width: 100%;
        height: 50%;
        text-align: right;
      }
    }
    .section-content {
      width: 70%;
      height: 100%;
      .reports-column-chart {
        display: flex;
        place-content: center;
        width: 100%;
        height: 50%;
      }
      .reports-pie-container {
        display: flex;
        place-content: center;
        width: 100%;
        height: 50%;
        .reports-pie-chart {
          width: 80%;
          height: 100%;
          display: flex;
          place-content: center;
          .chart-pie {
            display: flex;
            place-content: center;
          }
        }
        .legend-container {
          width: 20%;
          height: 100%;
          display: flex;
          flex-direction: column;
          gap: 2.5%;
          padding-right: 1rem;
          .legend {
            display: flex;
            gap: 5%;
            height: max-content;
            width: 100%;
            p {
              display: flex;
              align-items: center;
              text-wrap: wrap;
              width: 100%;
              margin: 0;
            }
          }
        }
      }
    }
  }
  .reports-right-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 35%;
    height: calc(100vh - 80px);
    align-items: stretch;

    .sidebar-alerts-container {
      background-color: #fff;
      padding-right: 1rem;
      height: 100%;
      .sidebar-alerts-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-right: 1rem;
        margin-left: 1rem;

        h3 {
          color: #000;
          font-family: 'GothamRounded';
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        a {
          color: #777985;
          font-family: 'GothamRounded';
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-decoration: none;
        }
      }
      .sidebar-alerts-body {
        height: 90%;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 0.3rem;
        }
        &::-webkit-scrollbar-thumb {
          background: rgba(3, 78, 103, 0.26);
        }
        &::-webkit-scrollbar-thumb:hover {
          background: rgba(3, 78, 103, 0.5);
        }
        .sidebar-alerts-item {
          display: flex;
          border-bottom: 1px solid #f6f6f6;
          .sidebar-alerts-item-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1rem;
          }
          .sidebar-alerts-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 100%;

            .sidebar-alerts-item-header {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              height: 50%;
              h4 {
                font-family: 'GothamRounded';
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                margin-top: 0;
                margin-bottom: 0;
              }
              span {
                font-family: 'GothamRounded';
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
              }
            }
            .sidebar-alerts-item-body {
              line-height: 0.2rem;
              height: 50%;
              min-width: 100%;
              p {
                font-family: 'GothamRounded';
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: break-word;
                max-width: 100%;
                min-height: 100%;
                padding-top: 0.5rem;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}
