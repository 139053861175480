@import "designSystem/colors.scss";

.table-content {
    padding: 40px;
    box-sizing: border-box;

    .table-filters {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 40px;

        .table-sort {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            .sort-option {
                font-size: 13px;
                font-weight: 500;
                color: $font-primary;
                margin-right: 20px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                user-select: none;

                &:hover {
                    cursor: pointer;
                    svg {
                        opacity: 1;
                    }
                }

                span {
                    text-transform: capitalize;
                }

                &.name {
                    width: 150px;
                    margin-left: 20px;
                }

                &.message {
                    flex: 1;
                }

                &.average_rating {
                    margin-right: 160px;
                }

                svg {
                    margin-left: 8px;
                    opacity: 0.5;
                }
            }

            .edit {
                flex: 0;
                padding: 5px;
                // background-color: #0f0;
            }
        }
    }

    .table-content-body {
        display: flex;
        flex-direction: column;
        width: 100%;

        &.rounded {
            border-radius: 30px;
            overflow: hidden;
        }

        tbody {
            flex: 1;
            width: 100%;
            border-collapse: collapse;

            tr {
                width: 100%;
            }
        }
    }

    .table-pagination {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 13px;
        font-weight: 500;
        color: $font-primary;
        width: 100%;

        .pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            user-select: none;

            .change-page {
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }
            }

            span {
                margin: 0 10px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .table-content {
        padding: 20px;

        .table-content-body {
            overflow-x: auto;
        }
    }
}
