@import "./src/designSystem/colors.scss";

.chat-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .chat-header {
        grid-area: Header;
        width: 100%;
        padding: 0 40px;
        display: flex;
        align-items: center;
        background-color: $light-background;
        box-sizing: border-box;
    }

    .close-chat {
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .content-wrapper {
        padding: 10px 30px;
        flex: 1;
        box-sizing: border-box;
        height: 100%;
        overflow: hidden;
        background-color: $medium-background;
        display: flex;

        .contacts-wrapper {
            height: 100%;
            width: 350px;
            background-color: $light-background;
            border-right: 6px solid $medium-background;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            overflow-x: hidden;

            .contacts-options-wrapper {
                width: 100%;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                border-bottom: 1px solid $medium-background;

                .chat-option {
                    flex: 1;
                    padding: 20px 0;
                    text-align: center;
                    cursor: pointer;
                    font-weight: bold;

                    &.selected {
                        background-color: $primary;
                        color: $font-light;
                    }
                }
            }
        }
        .toggleContacts {
            display: none;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            svg {
                font-size: 24px;
            }
        }

        .messages-box-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: $light-background;
            border-radius: 8px 8px 0 0;

            .message-header {
                display: flex;
                justify-content: space-between;
                padding: 15px 35px;
                height: 60px;
                position: relative;
                border-bottom: 1px solid $medium-background;

                .bug-image-wrapper {
                    width: 85px;
                    height: 58px;
                    border-radius: 7px;
                    background-color: rgba(128, 127, 127, 0.2);
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .bug-infos {
                    padding: 0 10px;

                    display: flex;
                    justify-content: space-evenly;
                    flex-direction: column;

                    h4 {
                        margin: 0;
                    }
                }
            }
            .closed-chat {
                padding: 10px;
                background-color: $primary;
                color: $font-light;
                width: 100%;
                box-sizing: border-box;
                // position: absolute;
                // bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
            }

            .message-content {
                overflow: auto;
                background-color: $background;
                position: relative;
                flex: 1;

                .resposta {
                    transform: matrix(-1, 0, 0, 1, 0, 0);
                }
            }

            .message-footer {
                height: 75px;
                padding: 10px 20px;
                box-sizing: border-box;

                display: flex;
                align-items: center;
                background-color: $light-background;

                .attach-icon {
                    font-size: 20px;
                }

                .input-message {
                    background-color: $light-background;
                    width: 100%;
                }

                .send-icon-wrapper {
                    padding: 7px;
                    border-radius: 50%;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    background-color: $primary;
                    color: $light-background;

                    font-size: 20px;
                }

                .pointer {
                    cursor: pointer;
                }
            }

            .welcome {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                svg {
                    font-size: 72px;
                    color: $primary;
                    opacity: 0.8;
                }

                p {
                    margin: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 1250px) {
    .chat-wrapper {
        .show {
            display: flex !important;
        }

        .hide {
            display: none !important;
        }
        .content-wrapper {
            .contacts-wrapper {
                display: none;
                width: 100%;
            }
            .toggleContacts {
                display: flex;
            }
            .messages-box-wrapper {
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .chat-wrapper {
        grid-template-rows: 40px 1fr;

        .content-wrapper {
            padding: 0;

            .messages-box-wrapper {
                border-radius: 0 0 0 0;
                height: 100%;
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: 90px 1fr 75px;
            }

            .message-header {
                border-top: 1px solid $medium-background;
                padding: 15px 20px !important;
            }
        }
    }
}
