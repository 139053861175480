@import "designSystem/colors.scss";

.modal-edit-specie {
    .subspecies-list {
        grid-column: span 6;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            button{
                // margin-left: 20px;
            }
        }

        .content {display: flex;
            align-items: center;
            font-weight: 600;
            color: $font-primary;
            margin-bottom: 20px;
            max-height: 200px;
            overflow: auto;
            color: $font-primary;
            font-size: 14px;

            table {
                width: 100%;

                thead tr th,
                tbody tr td {
                    padding: 5px 25px 5px 0;
                    text-align: left;

                    .actions {
                        font-size: 18px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        .action {
                            width: 35px;
                            transition: 0.1s opacity;

                            &:hover {
                                opacity: 0.7;
                                cursor: pointer;
                                transition: 0.1s opacity;
                            }
                        }
                    }
                }
            }
        }
    }
}
