@import "designSystem/colors.scss";

.file-upload-button {
    display: flex;
    align-items: center;
    padding: 10px 28px;
    color: white;
    text-decoration: none;
    border-radius: 53px;
    cursor: pointer;
    font-size: 14px;
    font-family: "GothamRounded";
    font-weight: 600;
    background-color: $primary;
}
