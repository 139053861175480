.react-modal {
    & > .modal-header {
        padding: 20px 35px;
        & > .modal-users-pestid-header-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            & > h2 {
                color: black;
                font-size: 22px;
            }
        }
    }

    & > .modal-body > .modal-users-pestid-body-content {
        padding: 0px 35px;
        height: 100%;
        & > h3 {
            font-size: 14px;
            color: #313131;
        }
        & > .modal-users-pestid-body-content-success {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            flex-direction: column;
            height: 100%;
        }
        & > .modal-users-pestid-body-content-selector {
            display: flex;
            align-items: center;
            margin-top: 20px;
            & > h3 {
                font-size: 14px;
                color: #313131;
            }
            & > svg {
                margin-left: 15px;
                width: 10px;
            }
        }
        & > .modal-users-pestid-body-content-selector-roles-container {
            position: absolute;
            background-color: white;
            z-index: 9999;
            box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
            min-width: 200px;
            overflow: hidden;
            transition: all 0.2s ease;

            & .modal-users-pestid-body-content-selector-roles-row {
                padding: 12px 20px;
                transition: all 0.2s ease;
                cursor: pointer;

                &:hover {
                    background-color: rgba(20, 175, 141, 0.08);
                }

                & p {
                    font-size: 14px;
                    font-weight: 500;
                    margin: 0;
                    color: #2c3e50;
                    transition: color 0.2s ease;
                }

                &:hover p {
                    color: #14af8d;
                }
            }
        }

        & > .modal-users-pestid-body-content-selector-companies-container {
            position: absolute;
            background-color: white;
            z-index: 9999;
            box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
            width: 70%;
            overflow-y: auto;
            max-height: calc(100% - 415px);
            transition: all 0.2s ease;

            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-track {
                background: #f1f1f1;
                border-radius: 0 12px 12px 0;
            }

            &::-webkit-scrollbar-thumb {
                background: #888;
                border-radius: 10px;

                &:hover {
                    background: #555;
                }
            }

            & input {
                border: 1px solid #e0e0e0;
                margin: 12px 16px;
                padding: 10px 16px;
                border-radius: 8px;
                width: calc(100% - 32px);
                font-size: 14px;
                transition: all 0.2s ease;

                &:focus {
                    outline: none;
                    border-color: #14af8d;
                    box-shadow: 0 0 0 2px rgba(20, 175, 141, 0.1);
                }
            }

            & .modal-users-pestid-body-content-selector-companies-row {
                padding: 12px 20px;
                transition: all 0.2s ease;
                cursor: pointer;

                &:hover {
                    background-color: rgba(20, 175, 141, 0.08);
                }

                & p {
                    font-size: 14px;
                    font-weight: 500;
                    margin: 0;
                    color: #2c3e50;
                    transition: color 0.2s ease;
                }

                &:hover p {
                    color: #14af8d;
                }
            }
        }
    }

    & > .modal-footer > .modal-users-pestid-footer-content {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        & > .modal-users-pestid-footer-button {
            width: 95px;
            height: 45px;
        }
    }
}

.modal-users-pestid-body-content-upload-button {
    height: 152px;
    background-color: rgba(20, 175, 141, 0.05);
    border-radius: 10px;
    border: dashed 2px #14af8d;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    & > .modal-users-pestid-body-content-delete-button {
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 100px;
        padding: 5px;
        box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.1);
    }

    & > input {
        display: none;
    }
    & > p {
        width: 40%;
        text-align: center;
        margin: 0px;
    }
    & > h3 {
        margin: 0px;
    }
    & > img {
        height: 100%;
        object-fit: contain;
    }
}

.modal-users-pestid-body-content-upload-route {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(20, 175, 141, 0.07);
    margin: 20px 0px;
    border: dashed 2px #14af8d;
    border-radius: 10px;
    & > h3 {
        color: #14af8d;
    }
}

.back {
    background-color: white;
    color: gray;
}

.next {
    background-color: black;
}
