// @import "designSystem/colors.scss";

//palette
$primary: #50e3c2;
$primary-medium: #52c3aa;
$primary-dark: #178068;
$background: #f2f2f2;
$light-background: #fff;
$dark-background: #080c22;
$medium-background: #e5e5e5;

//fonts
$font-primary: #696969;
$font-title: #202020;
$font-subtitle: #b6b6b6;
$font-light: #fff;

$error: #f44336;

$immediate: #ff4741;
$slow: #fff535;
