@import 'designSystem/colors.scss';

.traps-wrapper-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.traps-wrapper {
    background: #95C7BC26;
    width: 100%;
    height: 100%;
    place-content: center;
    overflow-x: hidden;
    overflow-y: auto;
    & > .traps-content-header {
        padding: 0.25rem 2.5rem;
        height: min-content;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        & .back-arrow {
            cursor: pointer;
        }

        & > h2 {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: black;
            & > span {
                color: #777985;
            }
        }
        & > svg {
            margin-right: 1rem;
        }
    }
    & > .traps-content-wrapper {
        background: white;
        position: relative;
        box-sizing: border-box;
        min-height: fit-content;
        height: 85%;
        width: 95%;
        margin: 0 auto;
        & > .traps-content {
            display: flex;
            padding: 0.5rem 2rem;
            box-sizing: border-box;
            height: 100%;
            & > .traps-content-selected-route {
                display: flex;
                flex-direction: column;
                height: 100%;
                width: 100%;
                background-color: white;
                & > .traps-content-selected-route-header {
                    height: auto;
                    margin-left: 20px;
                    position: relative;
                    & > p {
                        color: #595959;
                        font-weight: 500;
                        font-size: 17px;
                        line-height: 152%;
                    }
                    & > button {
                        width: 122px;
                        padding: 5px 0px;
                        height: 45px;
                        background-color: #14AF8D;
                        @media (max-height: 768px) {
                            width: 15%;
                        }
                    }
                    & > .traps-content-selected-route-dropdown {
                        position: absolute;
                        width: calc(30vw);
                        height: 30vw;
                        background: white;
                        border: 1px solid #D8D8D8;
                        box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
                        border-radius: 5px;
                        z-index: 20;
                        overflow-y: auto;
                        .traps-content-selected-route-dropdown-item + .traps-content-selected-route-dropdown-item {
                            border-top: 1px solid #EBEBEB;
                        }
                        & > .traps-content-selected-route-dropdown-item {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
    
                            & > .traps-content-selected-route-dropdown-item-image {
                                border-radius: 4px;
                                display: flex;
                                place-content: center;
                                width: 20%;
                                margin: 1rem;
                                padding: 1rem;
                                background: #F5F5F5;
                                border-radius: 4px;
                                & > img {
                                    max-width: 100%;
                                    height: auto;
                                }
                            }
                            & > .traps-content-selected-route-dropdown-item-text {
                                width: 80%;
                                & > h3 {
                                    margin-bottom: 0rem;
                                    font-style: normal;
                                    font-size: 16px;
                                    line-height: 193.9%;
                                    color: #080C22;
                                }
                                & > p {
                                    margin-top: 0;
                                    font-style: normal;
                                    font-weight: 325;
                                    font-size: 14px;
                                    line-height: 193.9%;
                                    color: #080C22;
                                }
    
                            }
                        }
                    }
                }
                & > .traps-content-selected-route-wrapper-canvas{
                    width: 100%;
                    height: 100%;
                    & > .building-content-main-route{
                        border: 0px;
                    }
                }
             
                & > .traps-canvas-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto;
                    height: 100%;
                    @media (max-height: 768px) {
                        align-items: flex-start;
                    }
                    & > .traps-canvas-container {
                        position: relative;
                        width: 100%;
                        height: 90%;
                        & > div > .canvas-dropdown {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            background: white;
                            min-width: 240px;
                            padding: 0.938rem;
                            gap: 0.5rem;
                            border: 1px solid #D8D8D8;
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                            border-radius: 9px;
                            position: absolute;
                        }
                        @media (max-height: 768px) {
                            height: 80%;
                        }
                        & > canvas {
                            width: 40vw !important;
                            height: 100% !important;
                        }
                    }
                }
            }
        }
    }
}

.button-component > .button-content {
    width: 100%;
    & > svg {
        margin-left: 5px;
        & > path {
                stroke: white
        }
    }
}
