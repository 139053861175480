@import "./src/designSystem/colors.scss";

.pest-library-wrapper {
    .pest-library-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 80px;
        background-color: white;
        padding: 20px 40px;
        box-sizing: border-box;

        h1 {
            font-size: 21px;
            margin: 0;
            font-weight: 600;
            color: $dark-background;
        }
    }

    .add-pest-button-wrapper {
        display: flex;
        justify-content: flex-end;

        padding: 20px 40px 0px 20px;
        button {
            width: 150px;
        }
    }

    .library-empty {
        padding: 40px;
        box-sizing: border-box;
        font-weight: 500;
        color: $font-primary;

        &.padding-top {
            margin-top: 40px;
        }
    }

    
    .table-filters-custom {

        .sort-option{
            padding: 0px 30px;
        }
    }
}
