@import "designSystem/colors.scss";

.zipcodes-wrapper {
    .zipcodes-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 80px;
        background-color: white;
        padding: 20px 40px;
        box-sizing: border-box;

        h1 {
            font-size: 21px;
            margin: 0;
            font-weight: 600;
            color: $dark-background;
        }
    }

    .zipcodes-content {
        padding: 40px;
        display: flex;
        flex-direction: column;

        .input-title {
            margin-bottom: 10px;
            font-weight: 500;
            color: $font-primary;
        }

        textarea {
            background-color: #ffffff;
            border: 0;
            width: 100%;
            border-radius: 5px;
            padding: 20px;
            box-sizing: border-box;
            line-height: 25px;
            font-weight: 500;
            height: 150px;
            resize: none;
            color: $font-primary;

            &::placeholder {
                opacity: 0.5;
            }

            &:focus {
                outline: 0;
            }
        }

        button {
            margin-left: auto;
            margin-top: 20px;
            width: 100px;
        }
    }

    // .zipcodes-empty {
    //     padding: 40px;
    //     box-sizing: border-box;
    //     font-weight: 500;
    //     color: $font-primary;

    //     &.padding-top {
    //         margin-top: 40px;
    //     }
    // }
}

@media only screen and (max-width: 768px) {
    .zipcodes-wrapper {
        .zipcodes-header {
            height: 70px;
            padding: 20px;
        }

        // .zipcodes-empty {
        //     padding: 20px;
        // }
    }
}
