@import "designSystem/colors.scss";

.review-card {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 10px 20px;
    border-radius: 12px;
    -webkit-box-shadow: 0px 5px 20px 0px rgba(186, 186, 186, 1);
    -moz-box-shadow: 0px 5px 20px 0px rgba(186, 186, 186, 1);
    box-shadow: 0px 5px 20px 0px rgba(186, 186, 186, 0.6);
    margin-bottom: 30px;
    position: relative;
    box-sizing: border-box;
    height: 60px;

    &.green-bg {
        background-color: #eafffa;
        border: 1px solid #c6eae2;
    }

    &.yellow-bg {
        background-color: #fbecc2;
        border: 1px solid #f7e0a1;
    }

    &.gray-bg {
        border: 1px solid #dedede;
        background-color: #e6e6e6;
    }

    .review-picture {
        height: 40px;
        width: 40px;
        flex-shrink: 0;
        margin-right: 15px;

        img {
            height: 100%;
            width: 100%;
            border-radius: 100%;
        }
    }

    .review-name {
        color: $font-primary;
        font-weight: 600;
        margin-right: 40px;
        font-size: 14px;
        width: 120px;
    }

    .review-status {
        color: $font-primary;
        width: 80px;
        font-size: 18px;
        margin-right: 20px;
    }

    .review-message {
        color: $font-primary;
        flex: 1;
        font-size: 14px;
    }

    .review-stars {
        display: flex;
        align-items: center;
        color: #f2f2f2;
        margin-right: 60px;
        margin-left: 30px;

        svg {
            margin: 0 2px;

            &.green {
                color: #50e3c2;
            }

            &.yellow {
                color: #ffc940;
            }
        }
    }

    .review-actions {
        display: flex;
        align-items: center;
        width: 150px;
        justify-content: flex-end;

        .accept-deny-buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 150px;

            .no-actions {
                width: unset;
            }
            
            button {
                width: 50%;
                margin-bottom: 0;
                flex-shrink: 0;
            }
        }

        .info-action {
            width: 18px;
            height: 18px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 5px;
            padding: 8px;
            border-radius: 5px;
            background-color: #f4f4f4;
            color: $font-primary;

            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }

            .info-action__loading {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img {
                padding-top: 3px;
                width: 100%;
            }
        }

        .redirect-action {
            min-width: 18px;
            height: 18px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(80, 227, 194, 0.15);
            color: #50e3c2;
            margin: 0 5px;
            padding: 8px;
            border-radius: 5px;

            svg {
                transform: scale(-1, 1);
            }

            span {
                font-weight: 500;
                font-size: 14px;
            }

            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .review-card {
        flex-direction: column;
        height: unset;

        .review-picture {
            margin: 0 0 15px 0;
        }

        .review-name {
            width: 100%;
            margin: 0 0 15px 0;
            text-align: center;
        }

        .review-message {
            margin: 0 0 15px 0;
        }

        .review-stars {
            margin: 0 0 20px 0;
        }

        .review-actions {
            width: unset;
        }
    }
}
