@import "./src/designSystem/colors.scss";

.report-wrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 24px;
    border-radius: 17px;
    background-color: $light-background;

    .header {
        display: flex;
        align-items: center;

        h2 {
            font-size: 20px;
            margin: 0;
            padding: 0;
            padding-right: 10px;
        }

        .icon {
            color: $primary;
            font-size: 24px;
        }
    }

    .date-filter {
        width: 100%;
        height: 70px;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 20px 0;
        box-sizing: border-box;

        .date-filter-label {
            margin-right: 10px;
            font-weight: 600;
            color: #4a4a4a;
        }

        .map-datepicker {
            padding: 5px 10px;
            border: 1px solid #b1b1b1;
            border-radius: 5px;
            margin-right: 10px;
            font-weight: 500;
            color: $font-title;
        }
    }

    .button-icon {
        margin-right: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 1080px) {
    .report-wrapper {
        align-items: flex-start;

        .date-filter {
            height: unset;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;

            .dates-wrapper {
                margin-top: 5px;
                width: 100%;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 10px;

                .map-datepicker {
                    box-sizing: border-box;
                    width: 100%;
                    padding: 5px;
                }
            }
        }
    }
}
