@import "designSystem/colors.scss";

.custom-table-header {
    background-color: white;
    border-bottom: 1px solid #f7f7f7;
    text-transform: capitalize;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    th {
        padding: 25px 30px;
        font-size: 15px;
        color: $font-title;
        font-weight: 600;
        text-align: left;
    }
}
