@import "./src/designSystem/colors.scss";

.google-map-drawable {
    width: 100%;
    height: 100%;
    position: relative;

    .draw-button-wrapper {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 999999;
        width: auto;

        &.when-drawing {
            background-color: $primary-dark;
        }
    }
}
