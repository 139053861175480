@import "./src/designSystem/colors.scss";

.FacilityModal-header-wrapper {
    margin: 10px;
    display: flex;
    color: $dark-background;
    font-size: 22px;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;

    .FacilityModal-close-button {
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 30px;

        cursor: pointer;
    }
}

.FacilityModal-content-wrapper {
    min-width: 400px;
    min-height: 200px;
}

.FacilityModal-footer-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 20px 0px;

    .button {
        width: 150px;
        margin-left: 20px;
        padding: 13px 0;
    }
}
