@import "designSystem/colors.scss";

.contact-pest-pro-card {
    display: flex;
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 30px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
-webkit-box-shadow: 0px 5px 20px 0px rgba(186, 186, 186, 1);
-moz-box-shadow: 0px 5px 20px 0px rgba(186, 186, 186, 1);
box-shadow: 0px 5px 20px 0px rgba(186, 186, 186, 0.6);

    .select-incidence {
        width: 40px;
        display: flex;
        align-items: center;

        input {
            width: 15px;
            height: 15px;
        }
    }

    .hoverable {
        transition: box-shadow 0.1s;

        &:hover {
            cursor: pointer;
            box-shadow: 0px 6px 26px rgba(0, 0, 0, 0.08);
            transition: box-shadow 0.1s;
        }
    }

    .clickable {
        cursor: pointer;
    }

    .contact-picture {
        margin-right: 20px;
        height: 70px;
        width: 70px;

        img {
            height: 100%;
            width: 100%;
            border-radius: 10px;
        }
    }

    .contact-user {
        display: flex;
    }

    .contact-content {
        display: flex;
        width: 100%;

        .contact-id {
            font-size: 12px;
            text-transform: uppercase;
            color: $font-primary;
            font-weight: 600;
            margin-bottom: 15px;
        }

        .contact-field {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 10px;
            flex: 1;

            &.user {
                flex: 1;
            }
            &.location {
                flex: 1;
            }
            &.pest_prediction {
                flex: 1;
            }
            &.confidence {
                flex: 1;
            }
            &.submitted_date {
                flex: 1;
            }
            &.status {
                flex: 1;
            }
            &.review_date {
                flex: 1;
            }
            &.who_reviewed_last {
                flex: 1;
            }

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                color: $primary;
                margin-right: 10px;
                width: 15px;
            }

            span {
                color: $font-primary;
                font-weight: 600;
                font-size: 14px;
                word-break: break-all;
            }
        }
    }

    .contact-tag {
        color: white;
        font-weight: 500;
        position: absolute;
        top: 20px;
        right: 20px;
        height: 25px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-weight: 500;
        border-radius: 3px;
        text-transform: capitalize;

        &.low {
            background-color: #50e3c2;
        }

        &.medium {
            background-color: #ffc940;
        }

        &.high {
            background-color: $error;
        }

        &.na {
            background-color: #bbbbbb;
        }
    }

    .checkbox-contact {
        input {
            margin: 0;
        }

        label {
            font-size: 13px;
            margin-left: 5px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .contact-pest-pro-card {
        .contact-picture {
            margin-right: 10px;
        }

        .contact-content {
            // flex-direction: column;
            flex-wrap: wrap;

            .contact-field {
                min-width: 60px;
                &:last-child {
                    margin-bottom: 0;
                }

                span {
                    font-size: 13px;
                }
            }
        }

        .contact-tag {
            padding: 0 5px;
            left: 31px;
            right: unset;
            bottom: 27px;
            top: unset;
            font-size: 10px;
            height: 17px;
            line-height: 17px;
        }

        .checkbox-contact {
            right: 20px;
            bottom: 10px;
        }
    }
}
