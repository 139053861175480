@import "designSystem/colors.scss";

.incidence-review-card-wrapper {
    display: flex;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 5px 20px 0px rgba(186, 186, 186, 1);
    -moz-box-shadow: 0px 5px 20px 0px rgba(186, 186, 186, 1);
    box-shadow: 0px 5px 20px 0px rgba(186, 186, 186, 0.6);

    .imgWrapper {
        width: 100px;
        height: 100px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
    }

    .info-wrapper {
        flex: 1;
        padding-left: 10px;
        display: flex;
        flex-direction: column;

        .row {
            display: flex;
            align-items: center;
            flex: 1;

            .icon {
                margin-right: 10px;
                margin-top: 2px;
            }

            .title {
                font-size: 14px;
                font-weight: 400;
            }

            &.buttons {
                justify-content: flex-end;
                font-size: 14px;
                font-weight: 500;
            }

            &.rejected {
                color: $error;
            }
            &.accepted {
                color: $primary-dark;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .incidence-review-card-wrapper {
        max-width: 100%;
        margin-bottom: 20px;
    }
}
