@import "designSystem/colors.scss";

.incidences-wrapper {
    overflow: scroll;
    height: 100%;
    .incidences-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 80px;
        background-color: white;
        padding: 20px 40px;
        box-sizing: border-box;

        h1 {
            font-size: 21px;
            margin: 0;
            font-weight: 600;
            color: $dark-background;
        }

        .actions {
            display: flex;
            margin-left: auto;
            align-items: center;

            .date-filter {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                box-sizing: border-box;

                .date-filter-label {
                    margin-right: 10px;
                    font-weight: 600;
                    color: #4a4a4a;
                }

                .map-datepicker {
                    padding: 5px 10px;
                    border: 1px solid #b1b1b1;
                    border-radius: 5px;
                    margin-right: 10px;
                    font-weight: 500;
                    color: $font-title;
                }
            }
        }
    }

    .submissions-count {
        display: grid;
        grid-gap: 20px;
        padding: 20px;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));

        .card {
            flex: 1;
            padding: 20px;
            border-radius: 15px;
            background-color: #fff;

            box-shadow: 0px 5px 20px 0px rgba(186, 186, 186, 0.3);

            .title {
                color: $font-subtitle;
                font-size: 15px;
                font-weight: 600;
            }

            .card {
                margin-top: 10px;
                color: $font-title;
                font-size: 15px;
                font-weight: 500;
                display: flex;
                align-items: flex-end;
            }

            .text-button {
                color: rgb(46, 46, 231);
                font-weight: 500;
                font-size: 12px;
                cursor: pointer;

                &.disabled {
                    color: $font-subtitle;
                    text-decoration: none;

                    &:hover {
                        cursor: progress;
                    }
                }
            }
        }
    }

    .top-buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 20px 40px 0px 40px;
        box-sizing: border-box;

        .csv-download-btn {
            width: auto;
            padding: 10px 28px;
            color: white;
            text-decoration: none;
            border-radius: 53px;
            cursor: pointer;
            font-size: 14px;
            font-family: "GothamRounded";
            font-weight: 600;
            background-color: $primary;

            svg {
                margin-right: 10px;
            }
        }
    }

    .incidences-empty {
        padding: 40px;
        box-sizing: border-box;

        .table-headers {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            font-weight: 500;

            .table-header {
                flex: 1;
            }
        }

        &.padding-top {
            margin-top: 40px;
        }
    }

    .table-content {
        width: 100%;

        .table-filters .table-sort .sort-option {
            &.name {
                width: 150px;
                margin-left: 20px;
            }

            &.id {
                flex: 1;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .incidences-wrapper {
        .incidences-header {
            padding: 20px;
            height: auto;
            flex-direction: column;

            .actions {
                flex-direction: column;
                width: 100%;
                margin-left: 0;

                .date-filter {
                    flex-direction: column;
                    width: 100%;
                    padding: 0;

                    .date-filter-label {
                        margin: 10px 0px;
                    }

                    .map-datepicker {
                        flex: 1;
                        width: 100%;

                        margin-bottom: 10px;

                        .react-datepicker-wrapper {
                            width: 100%;
                        }
                    }
                }

                .csv-download-btn {
                    text-decoration: none;
                    margin-left: auto;
                    padding: 10px 15px;
                    color: white;
                    border: none;
                    text-decoration: none;
                    border-radius: 53px;
                    cursor: pointer;
                    font-size: 12px;
                    font-family: "GothamRounded";
                    font-weight: 600;
                    background-color: $primary;

                    svg {
                        margin-right: 10px;
                    }
                }
            }
        }

        .top-buttons {
            flex-direction: column;
            & > div,
            button {
                margin-bottom: 10px;
            }
        }

        .incidences-empty {
            padding: 20px;
            width: 100%;
        }

        .table-content {
            width: 100%;

            .table-filters {
                display: none;
            }
        }
    }
}
