@import "designSystem/colors.scss";

.incidence-details-wrapper {
    .incidences-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 80px;
        background-color: white;
        padding: 20px 40px;
        box-sizing: border-box;

        h1 {
            font-size: 21px;
            margin: 0;
            font-weight: 600;
            color: $dark-background;
        }
    }

    .incidences-empty {
        padding: 40px;
        width: 50%;
        box-sizing: border-box;

        &.padding-top {
            margin-top: 40px;
        }
    }

    .table-content {
        flex: 1;
        padding: 40px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .incidence-details-wrapper {
        .incidences-empty {
            padding: 20px;
            width: 100%;
        }

        .table-content {
            padding: 20px;
            grid-template-columns: 1fr;
        }
    }
}
