@import "designSystem/colors.scss";

.contacts-wrapper {
    .contacts-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 80px;
        background-color: white;
        padding: 20px 40px;
        box-sizing: border-box;

        h1 {
            font-size: 21px;
            margin: 0;
            font-weight: 600;
            color: $dark-background;
        }
        .csv-download-btn {
            margin-left: auto;
            padding: 10px 28px;
            color: white;
            text-decoration: none;
            border-radius: 53px;
            cursor: pointer;
            font-size: 14px;
            font-family: "GothamRounded";
            font-weight: 600;
            background-color: $primary;

            svg {
                margin-right: 10px;
            }
        }
    }

    .contacts-empty {
        padding: 40px;
        box-sizing: border-box;
        font-weight: 500;
        color: $font-primary;

        &.padding-top {
            margin-top: 40px;
        }
    }

    .table-content {

        .table-filters .table-sort .sort-option {
            &.name {
                width: 150px;
                margin-left: 20px;
            }

            &.id {
                flex: 1;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .contacts-wrapper {
        .contacts-header {
            height: 70px;
            padding: 20px;
        }

        .contacts-empty {
            padding: 20px;
            width: 100%;
        }

        .table-content {
            width: 100%;

            .table-filters {
                display: none;
            }
        }
    }
}
