@import "./src/designSystem/colors.scss";

.message-card-wrapper {
    padding: 20px;
    display: flex;

    .user-image {
        img {
            width: 24px;
            padding: 10px 15px;
        }
    }

    .primary {
        color: $primary;
    }

    .message-wrapper {
        padding: 10px 15px;
        border-radius: 10px;
        box-shadow: 0px 13px 17px rgba(191, 191, 191, 0.15);
        width: fit-content;
        background-color: $light-background;

        .title {
            margin: 0;
        }

        .message {
            margin: 7px 0;

            font-weight: 325;
            font-size: 14px;
            line-height: 25px;

            img {
                width: 250px;

                cursor: pointer;
            }
        }

        .time {
            font-size: 11px;
            font-weight: 500;
        }

    }

}

.close-chat-modal-wrapper {
    position: absolute;
    top: 20px;
    right: 20px;

    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;

    border-radius: 50%;

    font-size: 28px;

    cursor: pointer;
}

.message-reply {
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

@media only screen and (max-width: 768px) {
    .message-card-wrapper {
        .message-wrapper {
            .message {
                img {
                    width: 170px;
                }
            }
        }
    }
}
