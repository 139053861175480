.react-modal {
    & > .modal-header{
        padding: 20px 35px;
        & >.modal-company-header-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            & > h2 {
                color: black;
                font-size: 22px;
                
            }
        }
    }
   
    
    & > .modal-body > .modal-company-body-content {
        padding: 0px 35px;   
        height: 100%;
        & > h3 {
            font-size: 14px;
            color: #313131;
        } 
        & > .modal-company-body-content-success {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            flex-direction: column;
            height: 100%;
        }
        & > .modal-company-body-content-selector-type{
            display: flex;
            align-items: center;
            margin-top: 20px;
            & > svg {
                margin-left: 15px;
            }
        }
        & > .modal-company-body-content-selector-type-container{
            position: absolute;
            background-color: white;
            z-index: 9999;
            border-radius: 10px;
            padding: 20px;
            box-shadow:  0px 4px 4px rgba(0, 0, 0, 0.25);
        }
    }

    & > .modal-footer > .modal-company-footer-content {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
                & > .modal-company-footer-button {
                    width: 95px;
                    height: 45px;
                }
    }
}

.modal-company-body-content-upload-button {
    height: 152px;
    background-color: rgba(20, 175, 141, 0.05);
    border-radius: 10px;
    border: dashed 2px #14AF8D;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    & > .modal-company-body-content-delete-button{
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 100px;
        padding: 5px;
        box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.1);
        }

    & > input {
        display: none;
    }
    & > p {
        width: 40%;
        text-align: center;
        margin: 0px;
    }
    & > h3 {
        margin: 0px;
    }
    & > img {
        height: 100%;
        object-fit: contain;
    }
}

.modal-company-body-content-upload-route{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(20, 175, 141, 0.07);
    margin: 20px 0px;
    border: dashed 2px #14AF8D;
    border-radius: 10px;
    & > h3{
        color: #14AF8D;
    }
}

.back {
    background-color: white;
    color: gray;
}

.next {
    background-color: black;
}