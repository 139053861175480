@import "designSystem/colors.scss";

.image-picker-wrapper {
    padding: 0 30px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .image-component-wrapper {
        max-width: 500px;
        height: 120px;
        border-radius: 3px;
        background-color: $background;


        display: block;
        display: flex;
        justify-content: center;

        cursor: pointer;

        .component-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: $background;
        }
    }

    .image-wrapper {
        width: 96px;
        height: 96px;
        border-radius: 50%;
        background-color: $dark-background;
        position: relative;
        display: block;

        .component-wrapper {
            width: 100%;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;

            color: $primary;

            svg {
                width: 30%;
                height: 30%;
            }

        }

        img {
            
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }

        .add {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: $primary-dark;
            position: absolute;
            bottom: 0;
            right: 10px;
            color: $font-light;
            display: flex;
            align-items: center;
            justify-content: center;

            cursor: pointer;

            &:hover{
                background-color: $primary;
            }
        }
    }

    h6 {
        text-align: center;
        color: $font-primary;
        font-weight: 600;
        font-size: 14px;
        margin: 10px 0;

    }
    
    .error {
        color: #f44336;
    }
}

@media only screen and (max-width: 767px) {
    .image-picker-wrapper {
        padding: 0;
    }
}
