@import './src/designSystem/colors.scss';

.chat-input-wrapper{
    width: calc(100% - 20px);
    border: 1px solid #D4D7DC;
    border-radius: 100px;
    box-sizing: border-box;

    padding: 10px 15px;
    margin: 0 10px;

    font-size: 14px;
    font-weight: 500;

    &:focus {
        outline: none;
    }
}