.react-modal{
    .viewer-trap-body{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        & > img {
            width: 90%;
            object-fit: contain;
            aspect-ratio: 1.5/1;
        }
    }
}
