.map-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    & > .map-search-box {
            display: flex;
            flex-direction: column;
            width: 100%;
            border: solid 1px;
            background-color: white;
            border-radius: 10px;
            margin: 30px 0px 10px 0px;
            & > .map-search-box-input {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                padding: 5px 10px;
                & > div {
                    width: 90%;
                }
                & > svg { 
                    width: 10%;
                }
            }
            
        }
    & > .map-alert{
        margin: 10px 0px;
    }
    & > #example-map{
        width: 100%;
        height: 100%;
        aspect-ratio: 2/1;
    }

}