@import "designSystem/colors.scss";

.pests-wrapper {
    .pests-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 80px;
        background-color: white;
        padding: 20px 40px;
        box-sizing: border-box;

        h1 {
            font-size: 21px;
            margin: 0;
            font-weight: 600;
            color: $dark-background;
        }
    }

    .top-buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 20px 40px 0px 40px;
        box-sizing: border-box;

        .csv-download-btn {
            padding: 10px 28px;
            color: white;
            text-decoration: none;
            border-radius: 53px;
            cursor: pointer;
            font-size: 14px;
            font-family: "GothamRounded";
            font-weight: 600;
            background-color: $primary;

            svg {
                margin-right: 10px;
            }
        }
    }

    .table-content {
        padding-top: 20px;
    }

    .pests-empty {
        padding: 40px;
        box-sizing: border-box;
        font-weight: 500;
        color: $font-primary;

        &.padding-top {
            margin-top: 40px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .pests-wrapper {
        .pests-header {
            height: 70px;
            padding: 20px;
        }

        .pests-empty {
            padding: 20px;
        }
    }
}