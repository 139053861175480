@import "./src/designSystem/colors.scss";

.manage-pest-library-modal-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
    width: 800px;
    max-width: 100%;
    padding: 20px;

    .row {
        display: grid;
        grid-gap: 20px;
        margin-bottom: 20px;
    }

    .row-2 {
        grid-template-columns: repeat(2, 1fr);
    }
    .row-3 {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 768px) {
    .manage-pest-library-modal-wrapper {
        .row-2,
        .row-3 {
            grid-template-columns: 1fr;
        }
    }
}
