@import 'designSystem/colors.scss';

.incidences-review-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .incidences-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 80px;
        background-color: white;
        padding: 20px 40px;
        box-sizing: border-box;

        h1 {
            font-size: 21px;
            margin: 0;
            font-weight: 600;
            color: $dark-background;
        }
    }

    .review-body {
        flex: 1;
        padding: 20px;
        display: flex;
        flex-direction: column;

        .review-incidence-card {
            background-color: #fff;
            padding: 40px;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            min-height: 100px;
            flex-shrink: 0;
            flex-wrap: wrap;

            .image-selection {
                margin-bottom: 40px;
                margin-right: 20px;

                .image-boxes {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    img {
                        width: 500px;
                        height: 500px;
                    }

                    .cropped-image {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    svg {
                        font-size: 24px;
                        margin: 0px 10px;
                    }

                    canvas {
                        width: 500px;
                        height: 500px;
                    }
                }

                .cropped-array {
                    width: unset;
                    height: 100%;
                    display: flex;
                    align-items: flex-start;
                    padding: 10px;
                    padding-top: 0px;
                    max-height: 500px;
                    max-width: 400px;
                    flex-wrap: wrap;
                    cursor: pointer;
                    margin-bottom: auto;

                    canvas {
                        width: 90px;
                        height: 90px;
                        margin-right: 10px;
                        margin-bottom: 10px;
                    }
                }

                .crop-buttons-wrapper {
                    width: 500px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .new-crop {
                        width: 200px;
                        margin-top: 20px;
                    }
                }
            }

            .field-title {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 10px;
                color: rgba(0, 0, 0, 0.54);
                line-height: 16px;

                .local-name {
                    color: $font-title;
                    padding-bottom: 10px;
                }
            }

            .field-input {
                margin-bottom: 40px;
                min-width: 300px;
                max-width: 100%;
                position: relative;

                .formControl {
                    display: block;
                }

                .message-checkbox {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;

                    input,
                    label {
                        cursor: pointer;
                    }
                }
            }

            .multiline-input {
                .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
                    padding-top: 20px;
                    font-size: 22px;
                    font-weight: 600 !important;
                    margin-bottom: 10px;
                    color: rgba(0, 0, 0, 0.45);
                    line-height: 16px;
                }

                .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
                    color: rgba(0, 0, 0, 0.45);
                }

                .css-g9cew8-MuiInputBase-root-MuiInput-root {
                    margin-top: 44px;
                }
            }

            .classification-wrapper {
                display: flex;
                flex: 1;

                form {
                    flex: 1;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: repeat(4, 100px);
                    column-gap: 40px;
                    height: 100%;

                    .add-new {
                        font-size: 14px;
                        font-weight: 500;
                        display: flex;
                        align-items: center;

                        span {
                            font-size: 16px;
                            margin-right: 5px;
                        }
                    }

                    .submit {
                        margin-top: auto;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        display: flex;
                        button {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1040px) {
    .incidences-review-wrapper {
        .review-body {
            .review-incidence-card {
                .image-selection {
                    .image-boxes {
                        img {
                            width: 350px;
                            height: 350px;
                        }

                        canvas {
                            width: 350px;
                            height: 350px;
                        }
                    }
                }
                .classification-wrapper {
                    padding-left: 20px;
                    margin-top: 20px;
                    height: auto;

                    .MuiInputBase-root {
                        min-height: 50px;
                    }
                    form {
                        flex: 1;
                        display: grid;
                        grid-template-columns: 1fr;
                        column-gap: 40px;
                        height: 100%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .incidences-review-wrapper {
        .review-body {
            .review-incidence-card {
                flex-direction: column;

                .image-selection {
                    .image-boxes {
                        img {
                            width: 250px;
                            height: 250px;
                        }

                        canvas {
                            width: 250px;
                            height: 250px;
                        }
                    }
                }

                .classification-wrapper {
                    padding: 0px;
                    margin-top: 20px;
                    height: auto;

                    .MuiInputBase-root {
                        min-height: 50px;
                    }

                    form {
                        flex: 1;
                        display: grid;
                        grid-template-columns: 1fr;
                        column-gap: 40px;
                        height: 100%;
                    }
                }
            }
        }
    }
}
