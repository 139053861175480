@import "./src/designSystem/colors.scss";

.option-card-wrapper {
    width: 100%;
    height: 230px;

    display: flex;
    align-items: center;
    flex-direction: column;

    border-radius: 15px;

    box-shadow: 0px 0px 4px #00000020;

    cursor: pointer;

    &.focused {
        box-shadow: 0px 0px 10px #00000030;
    }

    position: relative;

    .checked {
        position: absolute;
        top: 15px;
        right: 15px;

        width: 22px;
        height: 22px;

        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: $primary;
        color: $font-light;

        border-radius: 50%;
    }

    .card-icon {
        margin: 30px 0;
        width: 90px;
        height: 90px;

        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 50%;

        .icon-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 22px;
            height: 22px;

            border-radius: 50%;

            border: 2px solid $primary;
            color: $primary;

            font-size: 18px;
        }
    }

    .description-wrapper {
        padding: 0 70px;
        text-align: center;
        font-weight: bold;
    }
}
