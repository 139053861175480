.building-content-main-route {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    border-radius: 10px;
    position: relative;
    & > .building-content-main-route-wrapper{
        position: relative;

        & > .building-content-main-route-heatmap-container{
            position: absolute !important;
            top:0;
            left: 0;
        }

        & > .canvas-dropdown {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: white;
                min-width: 217px;
                padding: 0.938rem;
                gap: 0.5rem;
                border: 1px solid #D8D8D8;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 9px;
                position: absolute;
                z-index: 2;
              
                & > .canvas-dropdown-header {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    & > h4 {
                        margin: 0px;
                    }
                    & > p {
                        margin: 5px 0px 0px 0px;
                        font-size: 14px;
                    }
                }
                & > .canvas-dropdown-line {
                    border: none;
                    height: 1px;
                    background-color: #F6F6F6;
                    width: 100%;
                    margin: 0px;
                }
                & > .canvas-dropdown-buttons {
                    display: flex;
                    width: 100%;     
                    & > .canvas-dropdown-column {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        & > span {
                            width: 60%;
                            margin: 0px 0px 0px 8px;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 19px;
                            color: #000000;
                        }
                    }
                }
            }
            
        }
    
    & > img {
        width: 100%;
    }
}