@import "designSystem/colors.scss";

.pest-library-card {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 10px 20px;
    border-radius: 12px;
    -webkit-box-shadow: 0px 5px 20px 0px rgba(186, 186, 186, 1);
    -moz-box-shadow: 0px 5px 20px 0px rgba(186, 186, 186, 1);
    box-shadow: 0px 5px 20px 0px rgba(186, 186, 186, 0.6);
    margin-bottom: 30px;
    position: relative;
    box-sizing: border-box;
    height: 100px;

    .review-picture {
        height: 80px;
        width: 80px;
        flex-shrink: 0;
        margin-right: 15px;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }

    .row {
        color: $font-primary;
        font-weight: 600;
        margin-right: 20px;
        font-size: 14px;
        flex: 1;
        max-height: 100%;
    }

    .name {
        flex: 0 0 100px;
    }

    .description {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .dangerLevel {
        min-width: 70px;
    }

    .auto-width {
        flex: 0;
    }

    .review-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .accept-deny-buttons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 150px;

            .no-actions {
                width: unset;
            }

            button {
                width: 50%;
                margin-bottom: 0;
                flex-shrink: 0;
            }
        }

        .info-action {
            width: 18px;
            height: 18px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 5px;
            padding: 8px;
            border-radius: 5px;
            background-color: #f4f4f4;
            color: $font-primary;

            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }

            .info-action__loading {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img {
                padding-top: 3px;
                width: 100%;
            }
        }

        .redirect-action {
            min-width: 18px;
            height: 18px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(80, 227, 194, 0.15);
            color: #50e3c2;
            margin: 0 5px;
            padding: 8px;
            border-radius: 5px;

            svg {
                transform: scale(-1, 1);
            }

            span {
                font-weight: 500;
                font-size: 14px;
            }

            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }
        }
    }

    .delete {
        margin-left: 20px;
        cursor: pointer;
        font-size: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .review-card {
        flex-direction: column;
        height: unset;

        .review-picture {
            margin: 0 0 15px 0;
        }

        .review-name {
            width: 100%;
            margin: 0 0 15px 0;
            text-align: center;
        }

        .review-message {
            margin: 0 0 15px 0;
        }

        .review-stars {
            margin: 0 0 20px 0;
        }

        .review-actions {
            width: unset;
        }
    }
}
