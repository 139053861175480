@import "./src/designSystem/colors.scss";

.claim {
    background: linear-gradient(0deg, #080c22, #080c22), linear-gradient(222.98deg, #50e3c2 0%, #4ae7ae 101.48%);
    flex: 0 0 50%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-sizing: border-box;

    .header {
        display: flex;
        justify-content: center;
        padding: 10px 0;

        img {
            width: 100px;
            object-fit: contain;
        }
    }

    .footer {
        display: flex;
        justify-content: center;
        padding: 10px 0;
    }

    .claimBody {
        h1 {
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            letter-spacing: 0.02em;
            font-family: GothamRounded;
            font-style: normal;
            font-size: 50px;
            line-height: 60px;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        h2 {
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            letter-spacing: 0.02em;
            font-family: GothamRounded;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
        }
    }

    .powerby {
        font-style: bold;
        font-size: 15px;
        color: white;
    }
}
