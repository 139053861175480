@import "designSystem/colors.scss";

.custom-table-row {
    width: 100%;
    background-color: white;
    border-bottom: 1px solid #f7f7f7;
    box-shadow: 0px 6px 26px rgba(0, 0, 0, 0.02);

    td:not(.not-hoverable):hover {
        cursor: pointer;
        background-color: #f7f7f7;
    }

    td {
        padding: 25px 30px;
        font-size: 15px;
        color: $font-primary;
        font-weight: 500;
    }
}
