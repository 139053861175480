@import "./src/designSystem/colors.scss";

.card {
    max-width: 650px;
    border-radius: 17px;
    overflow: hidden;
    background-color: $light-background;
    margin: 40px 30px;

    .header {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        padding: 15px;

        border-bottom: 1px solid $medium-background;

        h1 {
            font-size: 21px;
            margin: 0;
            font-weight: 600;
            color: $dark-background;
        }

        .header-buttons-wrapper {
            display: flex;
            align-items: center;

            .cancel-button {
                width: unset;
                background-color: $medium-background;
                color: $font-title;
                padding: 10px 40px;
            }

            .edit-button {
                width: unset;
                display: flex;
                align-items: center;
                background-color: $dark-background;
                color: $font-light;

                .icon {
                    margin-right: 5px;
                    font-size: 18px;
                }

                img {
                    width: 18px;
                    height: 18px;
                    margin-right: 5px;
                }
            }

            button + button {
                margin-left: 15px;
            }
        }
    }

    .content {
        box-sizing: border-box;
        width: 100%;
        padding: 15px;
        background-color: $light-background;

        .infos {
            padding: 15px 0;
            padding-bottom: 25px;
        }

        .password-wrapper {
            position: relative;

            .reset {
                position: absolute;
                right: 0;
                top: 20px;
                color: $primary;
                font-weight: bold;
                border-bottom: 1px solid $primary;
                cursor: pointer;
            }

            .disabled {
                .MuiInput-underline.Mui-disabled:before {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
                }

                .MuiInput-underline:hover:before {
                    border-bottom: 2px solid rgba(0, 0, 0, 0.87);
                }
            }
        }

        .formControl {
            padding-bottom: 20px;

            .MuiInput-underline.Mui-disabled:before {
                border-bottom: none;
            }

            .MuiInputBase-input.Mui-disabled {
                color: $dark-background;
            }

            .css-1x51dt5-MuiInputBase-input-MuiInput-input.Mui-disabled {
                -webkit-text-fill-color: $dark-background;
            }
        }

        label {
            font-size: 14px;
            color: $dark-background;
            font-weight: 600;
            margin: 0;
        }
    }
}

.settings-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $dark-background;
    width: 500px;

    h1 {
        font-size: 22px;
        margin: 0;
        font-weight: 600;
        font-weight: bold;
    }

    .close-icon {
        font-size: 32px;
        font-weight: bold;
        cursor: pointer;
    }
}

.settings-modal-content {
    padding: 0 20px;
    padding-bottom: 30px;

    .formControl {
        padding-bottom: 20px;
    }

    label {
        font-size: 14px;
        color: $dark-background;
        font-weight: 600;
        margin: 0;
    }

    .settings-modal-button-wrapper {
        display: flex;
        justify-content: flex-end;

        .settings-modal-button {
            width: unset;
            background-color: $medium-background;
            color: $font-title;
            padding: 10px 40px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .card {
        width: unset;
        margin: 10px;
        .header {
            flex-wrap: wrap;
        }
    }

    .react-modal {
        .modal-header {
            .settings-modal-header {
                width: 80vw;
            }
        }

        .modal-body {
            .settings-modal-content {
                padding-bottom: 0;
            }
        }
    }
}
