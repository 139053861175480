@import "designSystem/colors.scss";

.profile-wrapper {
    .profile-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 80px;
        background-color: $font-light;
        padding: 20px 40px;
        box-sizing: border-box;

        h1 {
            font-size: 21px;
            margin: 0;
            font-weight: 600;
            color: $dark-background;
        }
    }

    .profile-content {
        padding: 40px;
        box-sizing: border-box;
        display: grid;
        grid-template-areas:
            "profile-name profile-name profile-actions"
            "profile-description profile-info profile-info"
            "profile-description profile-reviews profile-reviews"
            "button-wrapper button-wrapper button-wrapper"
            ;

        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 30px;

        .profile-name {
            grid-area: profile-name;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            label {
                position: relative;
                width: 75px;
                height: 75px;
                margin-right: 20px;
                // border: 2px solid white;
                flex-shrink: 0;

                img {
                    width: 100%;
                    height: 100%;
                }

                &:hover {
                    cursor: pointer;

                    .picture-overlay {
                        background-color: rgba(0, 0, 0, 0.3);
                        transition: background-color 0.1s;

                        svg {
                            display: block;
                        }
                    }
                }

                .picture-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0);
                    transition: background-color 0.1s;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $font-light;
                    font-size: 20px;

                    &.loading {
                        background-color: rgba(0, 0, 0, 0.3);

                        .loading-spinner {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        svg {
                            display: none;
                        }
                    }

                    svg {
                        display: none;
                    }

                    .loading-spinner {
                        display: none;
                    }
                }
            }

            h2 {
                font-size: 20px;
                margin: 0;
                font-weight: 600;
                color: $dark-background;
                margin-bottom: 5px;
            }

            .contact {
                font-size: 14px;
                margin: 0;
                font-weight: 400;
                color: $dark-background;
                margin-bottom: 5px;
                display: flex;
                align-items: center;

                svg {
                    margin-right: 10px;
                    color: $dark-background;
                    font-size: 16px;
                }
            }

            .user-data {
                font-weight: 500;
            }
        }

        .profile-actions {
            grid-area: profile-actions;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .switch-icon{
                margin-right: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .status{
                    font-size: 12px;
                    margin-bottom: 5px;
                }
            }

            .delete-icon {
                height: 40px;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #d3d3d3;
                color: $error;
                border-radius: 100%;
                margin-right: 20px;

                &:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }
            }

            .edit-icon {
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $dark-background;
                color: $font-light;
                border-radius: 40px;
                position: relative;
                padding: 0 20px;

                &:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }

                .edit-icon__title{
                    color: $font-light;
                    font-weight: bold;
                    margin-left: 10px;
                }
            }
        }

        .profile-description {
            grid-area: profile-description;
            background: #ffffff;
            border-radius: 10px;
            height: fit-content;
            min-height: 190px;

            .profile-description__title {
                font-size: 18px;
                margin: 0;
                font-weight: 600;
                color: $dark-background;
                border-bottom: 1px solid #f2f2f2;
                padding: 20px;
            }

            .profile-description__body {
                font-weight: 400;
                color: #595959;
                padding: 20px;
                font-size: 15px;
                line-height: 27px;
            }
        }

        .profile-info {
            grid-area: profile-info;
            background: #ffffff;
            border-radius: 10px;
            padding: 50px 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            .info-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                &:not(:last-of-type) {
                    border-right: 1px solid #f2f2f2;
                    margin-right: 50px;
                    padding-right: 50px;
                }

                .info-item__icon {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .info-item__text {
                    font-size: 16px;
                    font-weight: 600;
                    color: $dark-background;
                    margin-top: 15px;
                    text-align: center;
                }

                .info-item__value {
                    font-size: 14px;
                    font-weight: 500;
                    color: $font-primary;
                    margin-top: 10px;
                    text-align: center;
                }
            }
        }

        .button-wrapper{
            grid-area: button-wrapper;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            
            button {
                width: 100px;
            }
        }

        .profile-reviews {
            grid-area: profile-reviews;
            background: #ffffff;
            border-radius: 10px;

            .profile-reviews__title {
                font-size: 18px;
                margin: 0;
                font-weight: 600;
                color: $dark-background;
                border-bottom: 1px solid #f2f2f2;
                padding: 20px;
            }

            .profile-reviews__body {
                .review {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: 100%;
                    padding: 20px;
                    box-sizing: border-box;
                    border-bottom: 1px solid #f2f2f2;

                    .review-picture {
                        height: 40px;
                        width: 40px;
                        flex-shrink: 0;
                        margin-right: 15px;

                        img {
                            height: 100%;
                            width: 100%;
                            border-radius: 100%;
                        }
                    }

                    .review-name {
                        color: $font-primary;
                        font-weight: 600;
                        margin-right: 40px;
                        font-size: 14px;
                    }

                    .review-message {
                        color: $font-primary;
                        flex: 1;
                        font-size: 14px;
                    }

                    .review-stars {
                        display: flex;
                        align-items: center;
                        color: #f2f2f2;
                        margin-left: 30px;

                        svg {
                            margin: 0 2px;

                            &.green {
                                color: #50e3c2;
                            }

                            &.yellow {
                                color: #ffc940;
                            }
                        }
                    }
                }

                .reviews-empty {
                    padding: 20px;
                }

                .view-all {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 20px;
                    font-weight: 600;
                    font-size: 15px;
                    color: $primary;

                    &:hover {
                        cursor: pointer;

                        div {
                            opacity: 0.7;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .profile-wrapper {
        .profile-header {
            height: 70px;
            padding: 20px;
        }

        .profile-content {
            padding: 20px;
            grid-template-areas:
                "profile-name profile-actions"
                "profile-info profile-info"
                "profile-description profile-description"
                "profile-reviews profile-reviews";
            grid-gap: 20px;
            grid-template-columns: 1fr 100px;

            .profile-name {
                label {
                    margin-right: 20px;
                    width: 45px;
                    height: 45px;
                }

                h2 {
                    font-size: 17px;
                    margin: 0;
                    font-weight: 600;
                    color: $dark-background;
                }
            }

            .profile-description {
                min-height: unset;
            }

            .profile-info {
                flex-direction: column;

                .info-item {
                    &:not(:last-of-type) {
                        border-right: 0;
                        border-bottom: 1px solid #f2f2f2;
                        margin-right: 0;
                        padding-right: 0;
                        margin-bottom: 30px;
                        padding-bottom: 30px;
                    }
                }
            }

            .profile-reviews {
                .profile-reviews__body {
                    overflow-x: auto;

                    .review {
                        .review-message {
                            flex: 0 0 150px;
                        }
                    }
                }
            }
        }
    }
}
