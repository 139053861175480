@import "designSystem/colors.scss";

.map-wrapper {
    display: flex;
    height: 100%;

    .map-incidences {
        width: 320px;
        flex-shrink: 0;
        overflow-y: hiddden;
        background-color: white;
        overflow-y: auto;

        .mobile-toggle-incidences {
            display: none;
        }

        .incidences-list {
            .incidences-empty {
                padding: 30px;
                box-sizing: border-box;
                text-align: center;
                font-weight: 500;
            }

            .incidencesNumber {
                padding: 20px;
                font-size: 14px;
                font-weight: 500;
                text-align: center;
            }
        }
    }

    .map-container {
        flex: 1;

        iframe {
            border: 0;
            width: 100%;
            height: 100%;
        }

        .date-filter {
            position: absolute;
            width: 100%;
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.3) 0%,
                rgba(0, 0, 0, 0.1) 50%,
                rgba(255, 255, 255, 0) 100%
            );
            height: 70px;

            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 20px;
            box-sizing: border-box;

            .date-filter-label {
                margin-right: 10px;
                font-weight: 600;
                color: #4a4a4a;
            }

            .map-datepicker {
                padding: 5px 10px;
                border: 1px solid #b1b1b1;
                border-radius: 5px;
                margin-right: 10px;
                font-weight: 500;
                color: $font-title;
            }
        }

        .map-overlay-view {
            background-color: white;
            width: 300px;
            border-radius: 5px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.18);
            padding: 15px;
            box-sizing: border-box;

            &.invisible {
                display: none;
            }

            .overlay-title {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-weight: 600;
                margin-bottom: 15px;
                font-size: 15px;
                color: $font-title;

                img {
                    width: 17px;
                    height: 17px;
                    object-fit: contain;
                }

                span {
                    margin-left: 10px;
                }
            }

            .incidence-content {
                display: flex;

                .incidence-infos {
                    margin-right: 20px;

                    .overlay-item {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        font-size: 13px;
                        color: $font-primary;
                        font-weight: 500;

                        &:not(:last-of-type) {
                            margin-bottom: 10px;
                        }

                        .overlay-item__icon {
                            width: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            flex-shrink: 0;
                        }
                    }
                }

                .incidence-picture {
                    margin-left: auto;

                    img {
                        max-width: 80px;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}

#react-modal-image-img {
    max-width: 350px;
}

@media only screen and (max-width: 768px) {
    .map-wrapper {
        .map-incidences {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 100;
            display: flex;
            flex-direction: column;
            z-index: 3;

            &.list-invisible {
                height: fit-content;

                .incidences-list {
                    display: none;
                }
            }

            .mobile-toggle-incidences {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px;
                box-sizing: border-box;
                font-weight: 600;
                border-bottom: 1px solid #dadada;
            }

            .incidences-list {
                flex: 1;
            }
        }

        .map-container {
            padding-top: 60px;

            .date-filter {
                justify-content: flex-start;
                padding: 10px;

                .date-filter-label {
                    font-size: 12px;
                }

                .map-datepicker {
                    padding: 5px;
                    border: 1px solid #b1b1b1;
                    border-radius: 5px;
                    margin-right: 10px;
                    font-weight: 500;
                    color: $font-title;
                    font-size: 11px;
                    width: 130px;
                }
            }
        }
    }

    #react-modal-image-img {
        max-width: calc(100% - 40px);
    }
}
