@import "designSystem/colors.scss";

.send-message-wrapper {
    width: 1000px;
    min-height: 700px;
    box-sizing: border-box;
    padding: 30px;

    text-align: center;

    color: $font-primary;
    background-color: $light-background;

    position: relative;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 30px 1fr 40px;
    grid-gap: 16px;
    grid-template-areas:
        "Header"
        "Content"
        "Footer";

    .close-button {
        position: absolute;
        right: 30px;
        top: 30px;

        color: $font-subtitle;

        font-size: 22px;

        cursor: pointer;
    }

    .message-header {
        grid-area: Header;
        width: 100%;

        text-align: left;
        margin: 0;
    }

    .message-content {
        min-height: 100%;
        grid-area: Content;
        width: 100%;
        height: 100%;
        max-height: 300px;
    }

    .button-wreapper {
        grid-area: Footer;
        margin-top: auto;
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        button:last-child {
            margin-left: 15px;
        }
    }
}

@media only screen and (max-width: 1000px) {
    .send-message-wrapper {
        width: 100%;
        padding: 20px;
    }
}

@media only screen and (max-height: 700px) {
    .send-message-wrapper {
        min-height: 100%;
    }
}

@media only screen and (max-height: 400px) {
    .send-message-wrapper {
        min-height: 100%;
        .message-content {
            min-height: 230px;
            height: 100%;
            // background-color: #f0f;
        }
    }
}
