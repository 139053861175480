@import './src/designSystem/colors.scss';

.react-quill-wrapper{
    width: 100%;
    height:100%;

    .ql-toolbar{
        border-radius: 5px 5px 0 0;
        display: flex;
    }

    .ql-container{
        width: 100%;
        height: calc(100% - 42px);
        border-radius: 0 0 5px 5px;
        word-break: break-word;
    }
}