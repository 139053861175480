@import "designSystem/colors.scss";

.admin-reviews-wrapper {
    .admin-reviews-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 80px;
        background-color: white;
        padding: 20px 40px;
        box-sizing: border-box;

        h1 {
            font-size: 21px;
            margin: 0;
            font-weight: 600;
            color: $dark-background;
        }
    }

    .admin-reviews-empty {
        padding: 40px;
        box-sizing: border-box;
        font-weight: 500;
        color: $font-primary;

        &.padding-top {
            margin-top: 40px;
        }
    }

    .table-content {
        .table-filters .table-sort .sort-option {
            &.name {
                width: 195px;
                margin-left: 20px;
            }

            &.status {
                width: 80px;
            }

            &.message {
                flex: 0;
                margin-right: auto;
            }

            &.rating {
                margin-right: 280px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .admin-reviews-wrapper {
        .admin-reviews-header {
            height: 70px;
            padding: 20px;
        }

        .admin-reviews-empty {
            padding: 20px;
        }

        .table-content {
            .table-filters .table-sort {
                justify-content: space-between;
                
                .sort-option {
                    &.name {
                        width: unset;
                        margin: 0;
                    }

                    &.message {
                        flex: 0;
                        margin: unset;
                    }

                    &.rating {
                        margin: 0;
                    }
                }
            }
        }
    }
}
