@import "designSystem/colors.scss";

.edit-perdil-modal {
    position: absolute;
    background: rgb(255, 255, 255);
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -40%;
    transform: translate(-50%, -50%);
    border-radius: 11px;
    overflow: hidden;
    outline: none;

    .edit-profile-modal-wrapper {
        max-height: 90vh;
        width: 750px;
        display: flex;
        flex-direction: column;
        overflow: auto;

        .modal-header {
            padding: 20px 25px;
            font-weight: 600;
            font-size: 18px;
            color: #454449;
        }

        .modal-body {
            padding: 25px 25px 0 25px;
            max-width: 100%;
            box-sizing: border-box;

            .grid-wrapper {
                max-width: 100%;
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                grid-gap: 20px;
                margin-bottom: 20px;

                .fill-3 {
                    grid-column: span 3;
                }

                .fill-2 {
                    grid-column: span 2;
                }

                .fill-4 {
                    grid-column: span 4;
                }

                .fill-5 {
                    grid-column: span 5;
                }
                .fill-6 {
                    grid-column: span 6;
                }

                .license-map-wrapper {
                    display: flex;
                }

                .coverage-area-wrapper {
                    .map {
                        position: relative;

                        .expad-map-icon-wrapper {
                            position: absolute;
                            top: 10px;
                            right: 10px;

                            display: flex;
                            justify-content: center;
                            align-items: center;

                            background-color: $light-background;

                            svg {
                                padding: 8px;
                                width: 25px;
                                height: 25px;
                                z-index: 10000;

                                color: rgb(65, 65, 65);
                                background-color: $font-light;
                                box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
                                border-radius: 4px;
                            }

                            svg:hover {
                                color: $dark-background;
                            }
                        }

                        height: 200px;

                        &.map-focus {
                            height: 600px;
                            
                            transition: all 1s;
                        }

                        transition: all 1s;
                    }
                }
            }

            .input-wrapper {
                margin-bottom: 20px;
            }

            .input-title {
                color: rgba(0, 0, 0, 0.54);
                font-size: 17px;
                font-family: GothamRounded;
                font-weight: 600;
                letter-spacing: 0px;
                margin-bottom: 10px;
            }

            .input-subtitle {
                font-size: 14px;
                color: rgba(0, 0, 0, 0.54);
                font-weight: 600;
                margin-bottom: 5px;
            }

            .MuiFormLabel-root {
                font-weight: 600;
            }
        }
    }

    .map-modal-wrapper {
        height: 60vh;
        width: 750px;
        display: flex;
        flex-direction: column;
        overflow: auto;
    }

    .modal-footer {
        margin-top: auto;
        padding: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        button {
            min-width: unset;
            width: unset;
        }

        &.map-modal {
            position: absolute;
            bottom: 2px;
            right: 40px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .edit-perdil-modal {
        .edit-profile-modal-wrapper {
            width: 100%;

            .modal-body {
                .grid-wrapper {
                    grid-template-columns: 1fr;
                }
            }
        }
    }
}
