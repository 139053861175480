@import "./src/designSystem/colors.scss";

.forgot-password-pestid {
    display: flex;
    width: 100%;

    .formContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .back-icon{
            width: 75px;
            display: flex;
            align-items: center;
            cursor: pointer;
            font-weight: bold;
            margin-bottom: 10px;

            svg {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
        }
    }

    form {
        width: 440px;

        .formControl {
            display: block;

            margin-bottom: 20px;
        }
    }
}
