@font-face {
    font-family: "GothamRounded";
    src: url("./assets/fonts/GothamRounded-Light.otf");
    font-weight: 400;
}

@font-face {
    font-family: "GothamRounded";
    src: url("./assets/fonts/GothamRounded-Book.otf");
    font-weight: 500;
}

@font-face {
    font-family: "GothamRounded";
    src: url("./assets/fonts/GothamRounded-Medium.otf");
    font-weight: 600;
}

@font-face {
    font-family: "GothamRounded";
    src: url("./assets/fonts/GothamRounded-Bold.otf");
    font-weight: 700;
}

body,
* {
    font-family: "GothamRounded";
}

html,
body,
#root,
.App,
.App > div {
    margin: 0;
    height: 100%;
    box-sizing: border-box;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgb(228, 228, 228);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(192, 192, 192);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(170, 170, 170);
}
