@import "designSystem/colors.scss";

.dashboard-layout {
    display: flex;
    // height: unset !important;

    .sidebar {
        width: 250px;
        background-color: $dark-background;
        display: flex;
        flex-direction: column;
        overflow: auto; //todo REMOVE THIS !

        .logo {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 20px;
            box-sizing: border-box;
            height: 80px;

            .icon {
                height: 30px;
                margin-right: 15px;
            }

            .bedbug {
                // width: 120px;
                height: 15px;
                object-fit: contain;
            }

            .version {
                color: white;
                font-size: 9px;
                font-weight: 500;
                margin-left: 5px;
                margin-top: 3px;
            }
        }

        .sidebar-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 45px;
            padding-left: 30px;
            margin-bottom: 20px;
            color: $light-background;
            max-width: 180px;
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;
            text-decoration: none;
            font-weight: 600;
            transition: background-color 0.1s, color 0.1s;

            .sidebar-icon {
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 15px;
                font-size: 20px;

                img {
                    width: 100%;
                    height: 100%;
                }

                .icon-active {
                    display: none;
                }

                .icon-inactive {
                    display: block;
                }
            }

            &.active,
            &:hover {
                background-color: $primary;
                color: $primary-dark;
                cursor: pointer;
                transition: background-color 0.1s, color 0.1s;
                & > .sidebar-icon > svg > path {
                    stroke: $primary-dark;
                }

                .icon-active {
                    display: block;
                }

                .icon-inactive {
                    display: none;
                }
            }
        }

        .sidebar-subroutes-link {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 30px;
            padding-left: 45px;
            margin-bottom: 20px;
            color: $font-light;
            font-size: 13px;
            font-weight: 500;
            max-width: 180px;
            text-decoration: none;
            transition: background-color 0.1s, color 0.1s;

            .sidebar-icon {
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 15px;
                font-size: 16px;

                img {
                    width: 100%;
                    height: 100%;
                }

                .icon-active {
                    display: none;
                }

                .icon-inactive {
                    display: block;
                }
            }

            &.active,
            &:hover {
                color: $primary;
                cursor: pointer;
                transition: background-color 0.1s, color 0.1s;

                .icon-active {
                    display: block;
                }

                .icon-inactive {
                    display: none;
                }
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        background-color: $background;
        flex: 1;
        height: 100%;

        .top-bar {
            background-color: rgba(8, 12, 34, 0.94);
            height: 80px;
            padding: 20px 35px;
            box-sizing: border-box;
            display: flex;
            align-items: center;

            .mobile-options {
                display: none;
            }

            .logged-user-info {
                margin-left: auto;
                width: 230px;
                position: relative;
                flex-shrink: 0;

                .logged-user-info__content {
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    &:hover {
                        cursor: pointer;
                    }

                    img {
                        width: 45px;
                        height: 45px;
                        border: 2px solid white;
                        border-radius: 100%;
                    }

                    span {
                        margin: 0 15px;
                        font-weight: 500;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .logged-user-info__options {
                    position: absolute;
                    width: 200px;
                    background-color: white;
                    top: 65px;
                    right: 0;
                    box-shadow: 0px 0px 8px 0px rgba(216, 216, 216, 0.75);
                    z-index: 10;

                    .option {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        height: 30px;
                        padding: 10px 20px;
                        color: $font-primary;

                        &:hover {
                            cursor: pointer;
                            background-color: #f7f7f7;
                        }

                        span {
                            margin-left: 10px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        .content-children {
            overflow-y: auto;
            flex: 1;
            height: 100%;
        }
    }
}

@media only screen and (max-width: 768px) {
    .dashboard-layout {
        .sidebar {
            top: 80px;
            z-index: 10;
            position: absolute;
            width: 100%;
            height: 100%;
            display: none;

            &.mobile-visible {
                display: flex;
            }

            .logo {
                display: none;
                padding: 10px;
            }

            .sidebar-item {
                padding: 0 20px;
                margin: 0 20px;
                width: calc(100% - 40px);
                max-width: unset;
                justify-content: flex-start;
                border-radius: 10px;
                margin-top: 30px;
                box-sizing: border-box;
                height: 55px;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            background-color: $background;
            width: 100%;

            .top-bar {
                padding: 20px;

                .mobile-options {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    color: white;
                    flex: 1;

                    .sidebar-toggle {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 22px;
                        margin-right: 10px;
                        width: 30px;
                    }

                    .logo {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        padding: 20px;
                        box-sizing: border-box;

                        .icon {
                            height: 30px;
                            margin-right: 15px;
                        }

                        .bedbug {
                            width: 100px;
                        }

                        .version {
                            color: white;
                            font-size: 9px;
                            font-weight: 500;
                            margin-left: 5px;
                            margin-top: 3px;
                        }
                    }
                }

                .logged-user-info {
                    width: unset;

                    .logged-user-info__content {
                        img {
                            margin-right: 20px;
                            width: 40px;
                            height: 40px;
                        }

                        span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.pestid-icon {
    font-size: 12px;
}