@import "./src/designSystem/colors.scss";

.registerComponent {
    display: flex;
    width: 100%;

    .claim {
        background: linear-gradient(0deg, #080c22, #080c22), linear-gradient(222.98deg, #50e3c2 0%, #4ae7ae 101.48%);
        flex: 0 0 50%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        box-sizing: border-box;

        .header {
            display: flex;
            justify-content: center;
            padding: 10px 0;

            img {
                width: 100px;
                object-fit: contain;
            }
        }

        .footer {
            display: flex;
            justify-content: center;
            padding: 10px 0;
        }
    }

    .claim h1 {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        letter-spacing: 0.02em;
        font-family: GothamRounded;
        font-style: normal;
        font-size: 50px;
        line-height: 60px;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .claim h2 {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        letter-spacing: 0.02em;
        font-family: GothamRounded;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
    }

    .powerby {
        font-style: bold;
        font-size: 15px;
        color: white;
    }

    .formContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        form {
            width: 440px;

            .formControl {
                display: block;
                margin-bottom: 20px;
            }

            button:first-of-type {
                margin-top: 20px;
            }

            .separator {
                display: block;
                margin: 10px 0;
                text-align: center;
                font-size: 14px;
                line-height: 17px;
                color: $font-primary;
                font-weight: 600;
            }
        }
    }

    .error {
        color: red;
    }
}

@media only screen and (max-width: 768px) {
    .registerComponent {
        flex-direction: column;

        .claim {
            flex: 0 0 250px;
            justify-content: flex-start;

            .header {
                img {
                    width: 75px;
                    object-fit: contain;
                }
            }

            .claimBody {
                h1 {
                    font-size: 25px;
                    margin-top: 0;
                }

                h2 {
                    font-size: 16px;
                }
            }

            .footer {
                .powerby {
                    font-size: 11px;
                }
            }
        }

        .formContainer {
            form {
                width: 100% !important;
                padding: 20px;
                box-sizing: border-box;
            }
        }
    }
}
