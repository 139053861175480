@import "./src/designSystem/colors.scss";

.verification-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $dark-background;

    .confirmation-text {
        color: $font-light;
        text-align: center;
        margin-top: 40px;
    }
}
