@import 'designSystem/colors.scss';

.building-wrapper-loading{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.building-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    & > .building-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        padding: 20px 40px;
        box-sizing: border-box;

        h1 {
            font-size: 21px;
            margin: 0;
            font-weight: 600;
            color: $dark-background;
        }
        & > .building-header-button {
            width: 140px;
            height: 45px;
            background: black;
            border-radius: 47px;
            color: white;
        }
    }
    & >.building-content-wrapper {
        flex: 1;
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        
        & > .building-buildings-sidebar {
            display: flex;
            flex-direction: column;
            flex-shrink: 0;
            max-height: 50vh;
            overflow-y: auto;
            overflow-x: hidden;
            background: white;

            & > .building-buildings-sidebar-item {
                padding: 1rem 2rem;
                position: relative;
                & > .building-buildings-sidebar-location{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    & > h3 {
                        width: 90%;
                        font-size: 1.05em;
                        margin: 0px 0px 0px 15px;
                    }
                }
                & > .building-buildings-sidebar-item-arrow {
                    position: absolute;
                    right: 5%;
                    top: 50%
                }
                & > div {
                    margin-top: 10px;
                    & > h3 {
                        font-size: 1.12em;
                        margin: 0px;
                    }
                }
            }
        }

        & > .building-content {
            padding: 20px;
            display: flex;
            flex-direction: column;
            & > .building-content-header {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                width: 100%;
                align-items: center;
                margin-bottom: 20px;
                & > .building-content-header-buttons {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    & > .building-content-header-buttons-edit{
                        max-width: 110px;
                        height: 45px;
                        background-color: #14AF8D;
                        margin-right: 20px;
                    }
                    & > .building-content-header-buttons-delete{
                        width: 45px;
                        height: 45px;
                        padding: 10px;
                        background: #FFFFFF;
                        border: 1px solid #F6F6F6;
                        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.09);
                        border-radius: 47px;
                        margin-right: 10px;
                    }
                }
                & > .building-content-header-info{
                    width: 100%;
                    & > h2 {
                        font-size: 1.12em;
                        margin: 0px;
                    }
                    & > .building-content-header-location{
                        display: flex;
                        align-items: center;
                        margin: 30px 0px;
                        & > h4 {
                            margin: 0px 0px 0px 20px;
                            font-weight: normal;
                        }
                    }

                }
            }
            & > .building-content-main-route {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                width: 100%;
                aspect-ratio: 1/1;
                background: #FFFFFF;
                border: 1px solid #D8D8D8;
                border-radius: 10px;
                position: relative;
                & > .building-content-main-route-wrapper{
                    position: relative;
                    & > .building-content-main-route-heatmap-container{
                        position: absolute !important;
                        top:0;
                        left: 0;
                    }
                }
                & > img {
                    width: 100%;
                }
            }
            & > .building-content-building {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 0px 10px 0px;
                overflow-y: hidden;
                overflow-x: auto;
                padding-right: 10px;
                & > .building-content-route-item {
                    display: flex;
                    flex-direction: column;
                    box-sizing: border-box;
                    min-width: 50%;
                    margin-right: 10px;
                    background: #F6F6F6;
                    border: 1px solid #D8D8D8;
                    border-radius: 10px;
                    padding: 1rem;
                    & > .building-content-main-route-image {
                        height: 50%;
                        object-fit: contain;
                        border-width: solid 6px red;
                    }
                    & > h3 {
                        font-size: 16px;
                        line-height: 193.9%;
                        color: #080C22;
                        margin-bottom: 0;
                    }
                    & > p {
                        font-style: normal;
                        font-weight: 325;
                        font-size: 14px;
                        line-height: 193.9%;
                        color: #080C22;
                        margin: 0;
                    }
                    & > .wrapper-canvas {
                        height: 65%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                       
                    }
                }
            }
        }

        & > .building-content-no-buildings {
            display: flex;
            justify-content: center;
            margin: 0px 40px;
            align-items: center;
            height: 50px;
            & > h4 {
                margin-left: 10px;
            }
        }
    }
   
    
}

.react-modal {
    & > .modal-header >.modal-header-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    & > .modal-body > .modal-body-content {
        padding: 0px 35px;   
        height: 100%; 
        & > .modal-body-content-selector-companies{
           display: flex;
           align-items: center;
           & > svg {
                margin-left: 15px;
           }
        }
        & > .modal-body-content-selector-companies-container{
            position: absolute;
            background-color: white;
            z-index: 9999;
            border-radius: 10px;
            box-shadow:  0px 4px 4px rgba(0, 0, 0, 0.25);
            width: 70%;
            overflow: overlay;
            & input {
                border: solid 1px gray;
                margin: 5px 10px;
                padding: 5px 5px;
                border-radius: 5px;
            }
            & .modal-users-pestid-body-content-selector-companies-row{
                padding: 5px 20px;
                & p{
                    font-size: 15px;
                    font-weight: 500;
                }
            }
        }
        & > .modal-body-content-row {
            display: flex;
            flex-direction: column;
            padding: 20px;
            border: 1px solid #EDEDED;
            border-radius: 10px;
            margin-top: 10px;
            & > .modal-body-content-row-header {
                position: relative;
                & > .modal-body-content-row-header-menu {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    & > .modal-body-content-row-header-menu-dropdown {
                        background: #FFFFFF;
                        border: 1px solid #D8D8D8;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
                        border-radius: 9px;
                        padding: 10px 15px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        & > span {
                            margin-left: 8px;
                            font-weight: 600;
                        }
                        & > svg > path {
                            stroke: red;
                        }
                    }
                }
            }
        }
        & > .modal-body-content-success {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            flex-direction: column;
            height: 100%;
        }
    }

    & > .modal-footer > .modal-footer-content {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
                & > .modal-footer-button {
                    width: 95px;
                    height: 45px;
                }
    }
}

.modal-body-content-upload-button {
    height: 152px;
    background-color: rgba(20, 175, 141, 0.05);
    border-radius: 10px;
    border: dashed 2px #14AF8D;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    & > .modal-body-content-delete-button{
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 100px;
        padding: 5px;
        box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.1);
        }

    & > input {
        display: none;
    }
    & > p {
        width: 40%;
        text-align: center;
        margin: 0px;
    }
    & > h3 {
        margin: 0px;
    }
    & > img {
        height: 100%;
        object-fit: contain;
    }
}
.modal-body-content-upload-route{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(20, 175, 141, 0.07);
    margin: 20px 0px;
    border: dashed 2px #14AF8D;
    border-radius: 10px;
    & > h3{
        color: #14AF8D;
    }
}

.back {
    background-color: white;
    color: gray;
}

.next {
    background-color: black;
}

.button-upload {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    & > p {
        margin: 0px 0px 0px 10px;
    }
}



@media (min-width: 768px) {
    .building-wrapper .building-content-wrapper > .building-content > .building-content-main-route {
        width: 100%;
        aspect-ratio: auto;
        & > img {
            width: 60%;
        }
    }
  }
  
@media (min-width: 1024px) {
    .building-wrapper .building-content-wrapper > .building-content {
        & > .building-content-building > .building-content-route-item {
            min-width: 30%;
        }
        & > .building-content-header {
            flex-direction: row;
            & > .building-content-header-info > .building-content-header-location{
                margin: 10px 0px 0px 0px;
            }
            & > .building-content-header-buttons{
                justify-content: flex-end;
            }
        } 
    }
}
  
@media (min-width: 1100px) {
    .building-wrapper .building-content-wrapper {
        flex-direction: row;
        height: 100%;
        width: 100%;
        & > .building-buildings-sidebar{
            width: 30%;
            max-height: 100%;
        }
        & > .building-content {
                height: 100%;
                width: calc(70% - 30px);
            & > .building-content-header{
                height: 10%;
            }
            & > .building-content-main-route{
                height: 45%;
                & > img{
                    height: 90%;
                    object-fit: contain;
                }
            }
            & > .building-content-building::-webkit-scrollbar {
                display: none;
            }
            & > .building-content-building {
                height: 45%;
                & > .building-content-route-item{
                    height: 100%;
                }
            }
        }
    }
}